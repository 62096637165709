import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {ToggleButton} from "../../../common/components/ToggleButton";
import {
    loadActivityParticipantsList,
    resetParticipantsList,
    updateActivityParticipantsUpdate
} from "./ActivityParticipantsPageSlice";
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../app/hooks";


export const ActivityParticipantsPageContainer = (props) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const {loading, participantsList} = useAppSelector((root) => root.activityParticipantsPage)
    const [updatedParticipantsList, setUpdatedParticipantsList] = useState(participantsList)
    const [saveButtonActive, setSaveButtonActive] = useState(false)

    const linkData = props.location.state;

    useEffect(() => {
        if (linkData) {
            dispatch(loadActivityParticipantsList(linkData))
        }
    }, [])

    useEffect(() => {
        // delete Redux read only from properties
        setUpdatedParticipantsList(JSON.parse(JSON.stringify(participantsList)))
    }, [participantsList])


    const handlePresentToggle = (bookingsId, index) => {
        let tempUpdatedParticipantsList = {...updatedParticipantsList}
        tempUpdatedParticipantsList.participants[bookingsId][index] = {
            ...tempUpdatedParticipantsList.participants[bookingsId][index],
            present: tempUpdatedParticipantsList.participants[bookingsId][index].present === 0 ? 1 : 0
        };

        setUpdatedParticipantsList(tempUpdatedParticipantsList)
        setSaveButtonActive(true)
    }

    const submitList = () => {
        setSaveButtonActive(false)
        dispatch(updateActivityParticipantsUpdate(updatedParticipantsList.participants))
        history.goBack()
        dispatch(resetParticipantsList());
    }


    const formatDate = (dateTimeString) => {
        const date = new Date(dateTimeString)
        return date.toLocaleDateString("nl-NL", {weekday: 'long', day: 'numeric', month: 'long', year: 'numeric',});
    }

    const presentTotal = () => {
        let totalPresent = 0;
        if (updatedParticipantsList.participants) {
            Object.values(updatedParticipantsList.participants).map(participantsGroup => {
                participantsGroup.map(participant => {
                    if (participant.present == 1) {
                        totalPresent += 1
                    }
                })
            })
        }
        return totalPresent;
    }

    let counter = 1;

    if (!loading && updatedParticipantsList.participants) {

        return (
            <>
                <div className={'o-container o-container--primary p-activity-participants'}>

                    <header className="c-subject-header"><h1>Deelnemerslijst {updatedParticipantsList.title} -
                        aanwezigheid</h1>
                    </header>

                    <div className="p-activity-participants__date">
                        <span>{formatDate(updatedParticipantsList.date)}</span>
                    </div>

                    <div className="p-activity-participants__info">
                    <span className="p-activity-participants__info__amount">
                        <i className="icon2-user"/>{presentTotal() + '/ ' + updatedParticipantsList.total}
                    </span>

                    </div>

                    <div className="p-activity-participants__list">
                        <div className="p-activity-participants__list__header">
                            <span>Deelnemers</span>
                        </div>
                        <div className="p-activity-participants__list__participants">
                            {Object.values(updatedParticipantsList.participants).map((participantsGroup, indexGroup) =>
                                <ul
                                    key={participantsGroup + counter}
                                    className="p-activity-participants__list__participants__ul"
                                >
                                    {participantsGroup.map((participant, index) =>
                                        <li
                                            key={participant.name + counter}
                                            className="p-activity-participants__list__participants__participant">
                                         <span
                                             className="p-activity-participants__list__participants__participant__name">
                                             {`${counter++}. ${participant.name}`}
                                          </span>
                                            <div className="p-activity-participants__list__participants__button">
                                                <ToggleButton
                                                    key={participant.name + counter}
                                                    isToggledOn={participant.present}
                                                    handleOnToggle={() => handlePresentToggle(Object.keys(updatedParticipantsList.participants)[indexGroup], index)}/>
                                            </div>
                                        </li>
                                    )}
                                </ul>
                            )}
                        </div>

                    </div>

                    <div className={"p-activity-participants__buttons"}>
                        <button
                            className="btn btn--primary action-btn action-btn__back "
                            onClick={() => {
                                history.goBack()
                                dispatch(resetParticipantsList());
                            }}
                        >Terug
                        </button>

                        <button
                            disabled={!saveButtonActive}
                            className="btn btn--primary action-btn"
                            onClick={() => submitList()}
                        >Opslaan
                        </button>
                    </div>

                </div>
            </>
        )
    } else return <></>

}

