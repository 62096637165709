import React from 'react'
import ReactPaginate from 'react-paginate';
import DashboardAction from "../../../stores/dashboard/DashboardAction";
import moment from 'moment'
import {Link} from "react-router-dom";
import RouteEnum from "../../../common/constants/RouteEnum";
import {useSelector} from "react-redux";

const CategoryTable = (props) => {

    let issuingCategories = false

    if (props.items.data) {
        issuingCategories = getCategories(props.items.data)
    }
    const {slug} = useSelector((state) => state.environment)
    const pages = props.items.pages

    if (Object.values(props.items.data).length) {


        return (
            <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                <div className="c-table c-table--dashboard c-Rental-overview">
                    <table className={'c-availability'}>
                        <thead>

                        <tr>
                            <th>Item</th>
                            {Object.keys(props.items.data).map((day) => {
                                return <th>{moment(day).format('dd DD-MM-YYYY')}</th>
                            })}
                        </tr>

                        </thead>

                        <tbody>

                        {issuingCategories && issuingCategories.map((category) => {
                              return (
                                <tr key={'category-' + category}>
                                    <td className={'c-bicycle-name'}>{category}</td>

                                    {Object.keys(props.items.data).map((date) => {

                                        if (props.items.data[date] && props.items.data[date][category] && !props.items.data[date][category].show_participation_list) {
                                            return (
                                                <td key={'date-' + date +'-' + category}>{props.items.data[date][category].quantity}</td>
                                            )
                                        } else if (props.items.data[date] && props.items.data[date][category] && props.items.data[date][category].show_participation_list) {
                                            return (
                                                <td key={'date-' + date +'-' + category}>
                                                            <Link
                                                                to={{ pathname: '/' + slug + RouteEnum.ACTIVITY_PARTICIPANTS,
                                                                      state: {date: date, categoryId: props.items.data[date][category].category_id} }
                                                                }
                                                            >
                                                               <span className="c-info-blocks__category-link"> {props.items.data[date][category].quantity}</span>
                                                            </Link>
                                                </td>
                                            )
                                        } else return <td key={'no-date-' + date + '-' + category} />
                                    })}

                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {!!pages &&
                    <ReactPaginate
                        previousLabel={'< vorige'}
                        nextLabel={'volgende >'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pages.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        forcePage={parseInt(pages.current)}
                        activeClassName={'active'}
                    />
                    }

                </div>
            </div>
        )
    } else {
        return <p style={{padding: '2rem 1rem 0', fontSize: '1rem', textAlign: 'center'}}>Geen items gevonden...</p>
    }

    function getCategories(data) {

        const issuingCategories = []


        Object.keys(data).map((dateKey) => {

            const date = data[dateKey];

            if (date) {
                Object.keys(date).sort(function(a, b){
                    if(a.replace(/\s/g, '') < b.replace(/\s/g, '')) { return -1 }
                    if(a.replace(/\s/g, '') > b.replace(/\s/g, '')) { return 1 }
                    return 0 }).map((category) => {
                    if (!issuingCategories.includes(category)) {
                        issuingCategories.push(category)
                    }
                })
            }
        })


        return issuingCategories

    }

    function handlePageClick(i) {
        props.dispatch(DashboardAction.requestItemsInPeriod(false, false, false, false, false, i.selected))
    }

}

export default CategoryTable
