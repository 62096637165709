import ApiEnum from "../../common/constants/ApiEnum";
import ErrorAction from "../error/ErrorAction";
import {globalConfig} from "../../configuration/config";

export default class AuthenticationEffect {

    static async requestAccessToken() {

        return await fetch(globalConfig.get().apiUrl + ApiEnum.ApiAuthConnect, {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                key: globalConfig.get().key,
                secret:  globalConfig.get().secret
            })
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200 || response.status.code === 400) {

                return {
                    response: response.items,
                }

            } else {

                return {
                    error: true,
                    message: response.status.message
                }

            }
        }).catch(function (error) {

            return {
                error: true,
                message: error.message
            }
        })
    }


    static async login(values) {


        return await fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: 'post',
            body: JSON.stringify({
                ...values.params
            }),
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            switch (response.status.code) {
                case(200):

                    localStorage.setItem(values.slug + '-partnerId', response.items.partnerLocation.id);
                    localStorage.setItem(values.slug + '-defaultHolidayAddress', response.items.partnerLocation.default_holiday_address_id);

                    return {
                        success: true,
                        data: response.items.partnerLocation
                    }
                case(404):
                    return {
                        success: false,
                        notifications: {
                            error: 'Combinatie van e-mailadres en wachtwoord niet herkent. Probeer het opnieuw.'
                        }
                    }
                default:
                    return {
                        success: false,
                        notifications: {
                            error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                        }
                    }
            }


        }).catch(function () {
            return {
                success: false,
                notifications: {
                    error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                }
            }
        })


    }

    static async timeOut() {
        // No effect needed at the moment
        return true
    }


    static async logout() {
        return true
    }

    static async unsetUser() {
        return true
    }

    static async getUserByPin(values, dispatch) {

        return await fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: 'post',
            body: JSON.stringify({
                ...values.params
            }),
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            switch (response.status.code) {
                case(200):

                    localStorage.setItem('authToken', response.items.partnerUser.auth_token);
                    dispatch(ErrorAction.unsetError())

                    return {
                        success: true,
                        data: response.items.partnerUser,
                        notifications: false
                    }
                case(404):
                    return {
                        success: false,
                        notifications: {
                            error: 'Er is geen gebruiker gekoppeld aan deze pin code.'
                        }
                    }
                default:
                    return {
                        success: false,
                        notifications: {
                            error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                        }
                    }
            }


        }).catch(function (error) {

            return {
                success: false,
                notifications: {
                    error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                }
            }
        })

    }

}
