import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import ApiEnum from "../../common/constants/ApiEnum";
import fetchHelper from "../../common/helpers/fetchHelper";


export const loadProductRentalsPage = createAsyncThunk(
    'productRentalsPage/load',
    async (id, {getState, dispatch}) => {

        const itemValues = {
            fetchUrl: ApiEnum.Api + '/item/detail-by-id?id=' + id,
            method: 'post',
            accessToken: getState().authentication.accessToken,
            params: {
                auth_token: localStorage.getItem('authToken'),
                partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
            }
        }

        const rentalValues = {
            fetchUrl: ApiEnum.Api + '/item/history?id=' + id + '&limit=50',
            method: 'post',
            accessToken: getState().authentication.accessToken,
            params: {
                sort: "DESC",
                order_by: "id",
                auth_token: localStorage.getItem('authToken'),
                partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
            }
        }

        const itemResponse = await fetchHelper(itemValues, dispatch)
        const rentalResponse = await fetchHelper(rentalValues, dispatch)

        return {
            item: itemResponse?.items?.item,
            rentals: rentalResponse?.items
        }
    }
)

const initialState = {
    loading: false,
}

export const productRentalsPageSlice = createSlice({
    name: 'productRentalsPage',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(loadProductRentalsPage.fulfilled, (state, action) => {
            state.item = action.payload.item
            state.rentals = action.payload.rentals
        })
    },
})

export const {} = productRentalsPageSlice.actions

export const productRentalsPageReducer = productRentalsPageSlice.reducer