import {globalConfig} from "../../configuration/config";

export default class EnvironmentEffect {

    static async detail(values) {

        return await fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            switch (response.status.code) {
                case(200):
                    return {
                        success: true,
                        data: response.items.partner_environment,
                        slug: values.slug
                    }
                default:
                    return {
                        success: false,
                        notifications: {
                            error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                        }
                    }
            }

        }).catch(function () {
            return {
                success: false,
                notifications: {
                    error: 'Er heeft zich een onbekende fout voorgedaan. Probeer het later opnieuw.'
                }
            }
        })
    }

    static async enableRentalScanner(bool) {
        return bool
    }

    static async setLoader(bool) {
        return bool
    }

}
