import React from 'react'
import AvailabilityTable from "../../common/components/General/AvailabilityTable";
import {connect} from "react-redux";
import DashboardAction from "../../stores/dashboard/DashboardAction";
import Scanner from "../../common/components/General/Scanner";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.dashboard,
    environment: state.environment,
    rentalReturn: state.rentalReturn
});

class Availability extends React.Component {

    componentDidMount() {
        this.props.dispatch(DashboardAction.requestWeeklyAvailability())
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }


    render() {
        return (
            <div className={'o-container o-container--primary'}>

                <Scanner type={'rental'}/>

                <div className="s-dashboard-table">

                    <header
                        className="c-subject-header c-subject-header--closed c-subject-header--split"
                        style={{marginTop: '2rem'}}
                    >
                        <h2>{translate('availability')}</h2>
                    </header>

                    <AvailabilityTable {...this.props}/>

                </div>
            </div>
        );
    }

}

export default connect(mapStateToProps)(Availability)