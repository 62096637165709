import data from '../../json/translations.json'

let store
export const injectStoreTranslate = _store => {
    store = _store
}

const translate = (key) => {

    let string = key
    let language = 'nl'

    function capitalize(s) {
        return s[0].toUpperCase() + s.slice(1);
    }

    if (window.location.href.indexOf("/8/") > -1) {
        language = 'en'
    }

    data.filter(translateString => translateString["key"] === key).forEach(translateString => {
        string = translateString[language]
    })


    if (store?.getState().environment?.data?.item_label) {
        string = string.replace('*item_single*', store?.getState().environment?.data?.item_label)
        string = string.replace('*item_single_cap*', capitalize(store?.getState().environment?.data?.item_label))
    }

    if (store?.getState().environment?.data?.item_label_plural) {
        string = string.replace('*item_plural*', store?.getState().environment?.data?.item_label_plural)
        string = string.replace('*item_plural_cap*', capitalize(store?.getState().environment?.data?.item_label_plural))
    }

    return string

}

export default translate
