import fetchHelper from "../../common/helpers/fetchHelper";
import moment from 'moment'

export default class TransactionEffect {

    static async requestDetail(values, dispatch) {
        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.transaction
        } else return null

    }

    static async requestList(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response.items.data
        } else return null

    }

    static async requestListByLocation(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response && response.items && response.items.data) {
            return response.items
        } else return {
            data: null,
            totals: null,
            pages: null
        }

    }

    static async updateStatus(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async partialPayment(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }

    static async updatePaymentMethod(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {
            return response
        } else return null

    }


    static async setPeriod(data) {
        return data
    }

    static async setSearchValue(value) {
        return value
    }

    static async setPage(number) {
        return number
    }

}
