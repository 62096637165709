import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from "react-router-dom";
import axios, {AxiosResponse} from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import {camelizeKeys} from "humps";
import {apiEnum} from "../common/enums/apiEnum";
import {useEffect, useRef} from "react";
import {globalConfig} from "../../configuration/config";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch()
export const useAppSelector = useSelector
export const useAppLocation = () => useLocation()


export const useAppAxios = axios.create({
    headers: {
        "content-type": "application/json"
    },
    responseType: "json"
});

const refreshAuthLogic = (failedRequest) =>
    useAppAxios
        .post(apiEnum.AUTH + '/refresh', {
            refresh_token: localStorage.getItem("refreshToken")
        })
        .then(tokenRefreshResponse => {


            localStorage.setItem("accessToken", tokenRefreshResponse.data.items.accessToken.accessToken);
            localStorage.setItem("refreshToken", tokenRefreshResponse.data.items.accessToken.refreshToken);


            failedRequest.data.response.config.headers["Authorization"] =
                "Bearer " + tokenRefreshResponse.data.items.accessToken.access_token;
            return Promise.resolve();
        })
        .catch(error => {
            console.log("refresh fail");
            localStorage.removeItem("partnerUser");
            return Promise.reject(error);
        });

createAuthRefreshInterceptor(useAppAxios, refreshAuthLogic);

export const injectStore = _store => {
    store = _store
}

useAppAxios.interceptors.request.use((config) => {
    config.baseURL = globalConfig.get().apiUrl;
    return config;
});
// Use interceptor to inject the token to requests
useAppAxios.interceptors.request.use(request => {

    if (request.headers) {

        request.headers['Authorization'] = `Bearer ${getAccessToken()}`;

        if (request.method === 'post') {
            request.data['partner_id'] = store.getState().environment.data.info.partner_id
            request.data['partner_location_id'] = localStorage.getItem(store.getState().environment.slug + '-partnerId');
            request.data['master_partner_location_id'] = localStorage.getItem(store.getState().environment.slug + '-partnerId');

            if (localStorage.getItem('authToken')) {
                request.data['auth_token'] = localStorage.getItem('authToken')
            }
        }


        /**
         if (request.data) {
            request.data = decamelizeKeys(request.data);
        } */

    }

    return request;
});

let store


useAppAxios.interceptors.response.use((response) => {


    if (response.data.status.code === 401) {
        localStorage.removeItem("partnerUser");
    } else {
        response.data = camelizeKeys(response.data);
        return response;
    }
});

function getAccessToken() {
    return localStorage.getItem('accessToken');
}


export const useHasChanged = (val) => {
    const prevVal = usePrevious(val)
    return prevVal !== val
}

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
