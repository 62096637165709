export const translateTimeUnit = (unit) => {

    switch (unit.toLowerCase()){
        case('dag'):
            return 'day'
            break;
        case('uur'):
            return 'hour'
            break;
        case('kwartier'):
            return 'quarter'
            break;
        case('maand'):
            return 'month'
            break;
    }


}