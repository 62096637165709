import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerItemSetInService = async (item, serviceIds, remarks) => {
    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/item/set-in-service', {
        selected_service_defects: serviceIds,
        remarks: remarks
    }, {
        params: {
            id: item.id
        },
    }).then(function (response) {
        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
