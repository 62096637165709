import translate from "../helpers/utils/translate";

export const RentalFilter = (props) => {

    return (
        <div className={'c-filters c-form'}>

            <div className="c-form__row c-form__row--quarter">

                <div className="c-form__group">
                    <label htmlFor="">Fase</label>
                    <div className={'c-form__select'}>
                        <select value={props.filters.phase}
                                onChange={(e) => props?.onChange.phase(e.target.value)}
                        >
                            <option value={''}>{translate('allPhases')}</option>
                            <option value={'quotation'}>{translate('quotation')}</option>
                            <option value={'reservation'}>{translate('reservation')}</option>
                            <option value={'booking'}>{translate('booking')}</option>
                            <option value={'note'}>{translate('receipt')}</option>
                            <option value={'completed'}>{translate('completed')}</option>
                            <option value={'draft'}>{translate('concept')}</option>
                            <option value={'cancelled'}>{translate('cancelled')}</option>
                            <option value={'note_pending'}>{translate('receiptProcessing')}</option>
                            <option value={'registration'}>{translate('preRegistration')}</option>
                        </select>
                    </div>
                </div>

                <div className="c-form__group">
                    <label htmlFor="">{translate('startDate')}</label>
                    <input type={'date'}
                           value={props.filters.rentStart?.from}
                           onChange={(e) => props?.onChange.startDate(e.target.value)}
                    />
                </div>

                <div className="c-form__group">
                    <label htmlFor="">{translate('endDate')}</label>
                    <input type={'date'}
                           value={props.filters.rentStart?.to}
                           onChange={(e) => props?.onChange.endDate(e.target.value)}
                    />
                </div>

                <div className="c-form__group">
                    <label htmlFor="">{translate('partnerLocation')}</label>
                    <div className={'c-form__select'}>
                        <select
                            value={props.filters.partnerLocationId}
                            onChange={(e) => props?.onChange.partnerLocation(parseInt(e.target.value))}
                        >
                            <option value={0}>{translate('selectLocation')}</option>

                            {props.partnerLocations.map((partnerLocation) => {
                                return (
                                    <option value={partnerLocation.id}>{partnerLocation.name}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>

                <div className="c-form__group">
                    <label htmlFor="">{translate('outstandingAmount')}</label>
                    <div className={'c-form__select'}>
                        <select
                            value={props.filters.openPayment ? 'true' : ''}
                            onChange={(e) => props?.onChange.openPayment(!!e.target.value)}
                        >
                            <option value={''}>-</option>
                            <option value={'true'}>{translate('yes')}</option>
                        </select>
                    </div>
                </div>

                <div className="c-form__group">
                    <label htmlFor="">{translate('hasArticles')}</label>
                    <div className={'c-form__select'}>
                        <select
                            value={props.filters.items + ''}
                            onChange={(e) => props?.onChange.items(e.target.value + '')}
                        >
                            <option value={''}>-</option>
                            <option value={'1'}>{translate('yes')}</option>
                            <option value={'0'}>{translate('no')}</option>
                        </select>
                    </div>
                </div>


            </div>
        </div>
    )


}