const RouteEnum = {

    Login: process.env.PUBLIC_URL + '',

    Pin: process.env.PUBLIC_URL + '',
    PinForgot: process.env.PUBLIC_URL + '/pin/forgot',
    PinTimeOut: process.env.PUBLIC_URL + '/pin/time-out',

    Dashboard: process.env.PUBLIC_URL + '/dashboard',

    Search: process.env.PUBLIC_URL + '/dashboard/search',

    ScanItems: process.env.PUBLIC_URL + '/dashboard/scan-items',

    Availability: process.env.PUBLIC_URL + '/dashboard/beschikbaarheid',
    Settings: process.env.PUBLIC_URL + '/dashboard/instellingen',
    Stock: process.env.PUBLIC_URL + '/dashboard/voorraad',
    ItemsLate: process.env.PUBLIC_URL + '/dashboard/items-te-laat',

    TransactionOverview: process.env.PUBLIC_URL + '/dashboard/transacties',

    Rental: process.env.PUBLIC_URL + '/dashboard/rental',

    RentalDetail: process.env.PUBLIC_URL + '/dashboard/rental/detail',
    RentalInsurance: process.env.PUBLIC_URL + '/dashboard/rental/insurance',

    RentalIssueItems: process.env.PUBLIC_URL + '/dashboard/rental/issue-items',
    RentalReturnItems: process.env.PUBLIC_URL + '/dashboard/rental/return-items',
    RentalSwapItems: process.env.PUBLIC_URL + '/dashboard/rental/swap-items',

    RentalCreate: process.env.PUBLIC_URL + '/dashboard/rental/create',
    RentalCreatePayment: process.env.PUBLIC_URL + '/dashboard/rental/create/payment',
    RentalCreatePaymentResult: process.env.PUBLIC_URL + '/dashboard/rental/create/payment/result',

    RentalExtend: process.env.PUBLIC_URL + '/dashboard/rental/extend',
    RentalExtendPayment: process.env.PUBLIC_URL + '/dashboard/rental/extend/payment',
    RentalExtendPaymentResult: process.env.PUBLIC_URL + '/dashboard/rental/extend/payment/result',

    RentalPeriod: process.env.PUBLIC_URL + '/dashboard/rental/period',

    RentalItemsSwap: process.env.PUBLIC_URL + '/dashboard/rental/items/swap',
    RentalItemsSwapPayment: process.env.PUBLIC_URL + '/dashboard/rental/items/swap/payment',

    RentalItems: process.env.PUBLIC_URL + '/dashboard/rental/items',

    RentalCustomerData: process.env.PUBLIC_URL + '/dashboard/rental/address',
    RentalDelivery: process.env.PUBLIC_URL + '/dashboard/rental/delivery',

    RentalCancellation: process.env.PUBLIC_URL + '/dashboard/rental/cancel',
    RentalCancellationPayment: process.env.PUBLIC_URL + '/dashboard/rental/cancel/payment',
    RentalCancellationPaymentResult: process.env.PUBLIC_URL + '/dashboard/rental/cancel/payment/result',

    ACTIVITY_PARTICIPANTS: process.env.PUBLIC_URL + '/dashboard/activity-participants',

    PRODUCT_RENTALS: process.env.PUBLIC_URL + '/product/rentals',
    PRODUCT_INFO: process.env.PUBLIC_URL + '/product',
    EVENT_DEMAND: process.env.PUBLIC_URL + '/demand',


}

export default RouteEnum;
