import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalPeriodEffect from "./RentalPeriodEffect";
import moment from "moment";
import ApiEnum from "../../../common/constants/ApiEnum";
import MapValuesAsGetParameters from "../../../common/helpers/MapValuesAsGetParameters";
import CalculateDaysBetween from "../../../common/helpers/CalculateDaysBetween";

export default class RentalPeriodAction {

    static SET = 'rentalPeriodAction.SET';
    static SET_TIME = 'rentalPeriodAction.SET_TIME';

    static LOAD_FROM_INFO = 'rentalPeriodAction.LOAD_FROM_INFO';
    static LOAD_ENVIRONMENT_SETTINGS = 'rentalPeriodAction.LOAD_ENVIRONMENT_SETTINGS';

    static CHECK_AVAILABILITY = 'rentalPeriodAction.CHECK_AVAILABILITY';

    static RESET = 'rentalPeriodAction.RESET';
    static SUBMIT_MUTATION = 'rentalPeriodAction.SUBMIT_MUTATION';

    static set(period = false) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.SET, RentalPeriodEffect.set, period);
        }

    }

    static loadEnvironmentSettings() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.LOAD_ENVIRONMENT_SETTINGS, RentalPeriodEffect.loadEnvironmentSettings, getState().environment);
        }
    }


    static setTime(value) {

        return async (dispatch, getState) => {

            await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.SET_TIME, RentalPeriodEffect.setTime, value);

        }

    }

    static checkAvailability() {

        return async (dispatch, getState) => {

            if (getState().rentalDetail && getState().rentalDetail.data) {

                let newStartDate = moment(getState().rentalPeriod.period.startDate)
                let newEndDate = moment(getState().rentalPeriod.period.endDate)

                if (newStartDate.isBefore(moment())) {
                    newStartDate = moment()
                }

                if (newEndDate.isBefore(moment())){
                    newEndDate = moment()
                }

                const bicycleParams = {
                    startDate: newStartDate.format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                    endDate: newEndDate.format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                    timeUnitId: getState().rentalPeriod.period.chosenUnit,
                    rental: getState().rentalDetail.data?.data?.rental?.id,
                    auth_token: localStorage.getItem('authToken'),
                    partnerLocationId: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    visible_in_partner_environment: true
                }

                if (getState().rentalDetail) {

                    if (getState().rentalDetail?.data?.data?.partner_location.id) {
                        bicycleParams.partnerLocationId = getState().rentalDetail.data.data.partner_location.id
                    }

                    bicycleParams.rentalId = getState().rentalDetail.data.data.rental.uuid
                }

                const accessoireParams = {
                    ...bicycleParams,
                    type: 'accessoire'
                }


                const values = {
                    shoppingCartItems: getState().rentalDetail.data.data.rentalItems,
                    bicycles: {
                        fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(bicycleParams),
                        method: 'post',
                        accessToken: getState().authentication.accessToken,
                        params: bicycleParams,
                    },
                    accessoires: {
                        fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(accessoireParams),
                        method: 'post',
                        accessToken: getState().authentication.accessToken,
                        params: accessoireParams,
                    }
                }

                await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.CHECK_AVAILABILITY, RentalPeriodEffect.checkAvailability, values);

            }

        }

    }


    static loadFromInfo() {
        return async (dispatch, getState) => {

            if (getState().rentalDetail && getState().rentalDetail.data && getState().rentalDetail.data.data) {

                const values = {
                    period: {
                        amount: getState().rentalDetail.data.data.time_unit.amount,
                        chosenUnit: getState().rentalDetail.data.data.time_unit.amount.id,
                        startDate: moment(getState().rentalDetail.data.data.rental.date_rent_start).format('YYYY-MM-DD'),
                        endDate: moment(getState().rentalDetail.data.data.rental.date_rent_end).format('YYYY-MM-DD'),
                        endTime:  moment(getState().rentalDetail.data.data.rental.date_rent_end).format('HH:mm'),
                        startTime: moment(getState().rentalDetail.data.data.rental.date_rent_start).format('HH:mm')
                    },
                    time: moment(getState().rentalDetail.data.data.rental.date_rent_start).format('HH:mm')
                }

                await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.LOAD_FROM_INFO, RentalPeriodEffect.set, values);
            }
        }
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.RESET, RentalPeriodEffect.reset);
        }
    }

    static submitMutation() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                uuid: getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    date_start: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                    date_end: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                    time_unit_id: getState().rentalPeriod.period.chosenUnit,
                    type: 'shift'
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalPeriodAction.SUBMIT_MUTATION, RentalPeriodEffect.submitMutation, values);
        }
    }
}
