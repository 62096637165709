import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiPartnerLocationDetail} from "../../../common/api/partner/location/apiPartnerLocationDetail";
import {apiPartnerLocationOpeningTimes} from "../../../common/api/partner/location/apiPartnerLocationOpeningTimes";

export const loadSettings = createAsyncThunk(
    'settings/load',
    async (_, {getState}) => {
        const partnerLocationId = localStorage.getItem(getState().environment.slug + '-partnerId');
        const result = await apiPartnerLocationDetail(partnerLocationId);

        return {
            openingTimes: result.data.items.openingTimes
        }

    }
)

export const submitOpeningTimes = createAsyncThunk(
    'settings/submitOpeningTimes',
    async (_, {getState}) => {
        try {

            const {settingsPage} = getState();
            const result = await apiPartnerLocationOpeningTimes(settingsPage.openingTimeSettings.openingTimes);

        } catch (e) {
            console.log(e)
        }

    }
)
const initialState = {
    openingTimeSettings: {
        changed: false,
        submitting: false,
        openingTimes: []
    }
}

const initialOpeningDay = {
    isOpen: 1,
    openFrom: '00:00',
    openTo: '00:00',
    blockTime: 0,
    blockFrom: '00:00',
    blockTo: '00:00'
}

export const settingsPageSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        addOpeningTime: (state) => {
            if (!state.openingTimeSettings.openingTimes) {
                state.openingTimeSettings.openingTimes = []
            }

            state.openingTimeSettings.openingTimes.push({
                name: 'Nieuw',
                dateFrom: '',
                dateTo: '',
                openingDays: {
                    'Maandag': initialOpeningDay,
                    'Dinsdag': initialOpeningDay,
                    'Woensdag': initialOpeningDay,
                    'Donderdag': initialOpeningDay,
                    'Vrijdag': initialOpeningDay,
                    'Zaterdag': initialOpeningDay,
                    'Zondag': initialOpeningDay
                }
            })

        },
        removeOpeningTime: (state, action) => {

            state.openingTimeSettings.changed = true

            if (state.openingTimeSettings.openingTimes) {
                const {i} = action.payload
                state.openingTimeSettings.openingTimes = state.openingTimeSettings.openingTimes.filter((openingTime, key) => {
                    return key !== i
                })
            }
        },
        setOpeningTimeData: (state, action) => {

            state.openingTimeSettings.changed = true

            if (state.openingTimeSettings.openingTimes) {
                const {i} = action.payload
                state.openingTimeSettings.openingTimes = state.openingTimeSettings.openingTimes.map((openingTime, key) => {
                    if (i === key) {
                        return action.payload.openingTime
                    }
                    return openingTime
                })
            }
        },
        setOpeningTimeDayData: (state, action) => {

            state.openingTimeSettings.changed = true

            if (state.openingTimeSettings.openingTimes) {
                const {i, dayKey, day} = action.payload
                state.openingTimeSettings.openingTimes = state.openingTimeSettings.openingTimes.map((openingTime, key) => {

                    if (i === key) {
                        const keyTyped = dayKey;
                        openingTime.openingDays[keyTyped] = day
                    }

                    return openingTime

                })
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loadSettings.fulfilled, (state, action) => {
            state.openingTimeSettings.openingTimes = action.payload.openingTimes
        })
        builder.addCase(submitOpeningTimes.fulfilled, (state, action) => {
            state.openingTimeSettings.changed = false
        })
    },
})

export const {setOpeningTimeData, setOpeningTimeDayData, removeOpeningTime, addOpeningTime} = settingsPageSlice.actions


export const settingsReducer = settingsPageSlice.reducer
