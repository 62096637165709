import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalListEffect from "./RentalListEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import SetSortValues from "../../../common/helpers/SetSortValues";

export default class RentalListAction {

    static REQUEST_LIST = 'RentalListAction.REQUEST_LIST';
    static SET_LOADING = 'RentalListAction.SET_LOADING';
    static SET_PAGE = 'RentalListAction.SET_PAGE';
    static RESET = 'RentalListAction.RESET';
    static SET_SORT = 'RentalListAction.SET_SORT';
    static SET_FILTER = 'RentalListAction.SET_FILTER';

    static list(pageNumber) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/list?limit=12&page=' + pageNumber,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                sortBy: getState().rentalList.sort.sortBy,
                sortDirection: getState().rentalList.sort.sortDirection,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: parseInt(localStorage.getItem(getState().environment.slug + '-partnerId')),
                    sort: getState().rentalList.sort.sortDirection,
                    filter: getState().rentalList.filters

                }
            }

            SetSortValues(values, getState().rentalList.sort.sortBy, getState().rentalList.sort.sortDirection, getState().rentalList)

            await ActionUtility.createThunkEffect(dispatch, RentalListAction.REQUEST_LIST, RentalListEffect.list, values);
        }

    }

    static setLoader(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalListAction.SET_LOADING, RentalListEffect.setLoading, value);
        };
    }

    static setPage(pageNumber) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalListAction.SET_PAGE, RentalListEffect.setPage, pageNumber);
        };
    }

    static setSort(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalListAction.SET_SORT, RentalListEffect.setSort, values);
        }
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalListAction.RESET, RentalListEffect.reset);
        };
    }

    static setFilter(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalListAction.SET_FILTER, RentalListEffect.setFilter, values);
        }

    }

}
