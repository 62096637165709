import ActionUtility from "../../common/helpers/ActionUtility";
import DashboardEffect from "./DashboardEffect";
import ApiEnum from "../../common/constants/ApiEnum";
import moment from 'moment'
import SetSortValues from "../../common/helpers/SetSortValues";

export default class DashboardAction {

    static REQUEST_RENTALS_IN_PERIOD = 'DashboardAction.REQUEST_RENTALS_IN_PERIOD';
    static REQUEST_ITEMS_IN_PERIOD = 'DashboardAction.REQUEST_ITEMS_IN_PERIOD';
    static REQUEST_WEEKLY_AVAILABILITY = 'DashboardAction.GET_WEEKLY_AVAILABILITY';
    static SET_RENTAL_BUTTON = 'DashboardAction.SET_RENTAL_BUTTON';
    static SET_PAGE = 'DashboardAction.SET_PAGE';
    static RESET = 'DashboardAction.RESET';

    static requestRentalsInPeriod(display = false, deliveryFilter = false, sortBy = false, sortDirection = false, dates = false, page = false) {

        return async (dispatch, getState) => {

            display = display ? display : getState().dashboard.display.active
            deliveryFilter = deliveryFilter ? deliveryFilter : getState().dashboard.deliveryFilter.active

            const limit = 10
            let startDate = dates ? moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm') : getState().dashboard.period.start
            let endDate = dates ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm') : getState().dashboard.period.end

            /** RESET DATE IF THE DISPLAY HAS CHANGED **/
            if (display !== getState().dashboard.display.active) {
                startDate = moment().startOf('day').format('YYYY-MM-DD HH:mm')
                endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm')
            }

            console.log(startDate, endDate)

            /** GET ACTIVE PAGE IF A NEW PAGE ISNT PASSED **/
            if (!page && page !== 0) {
                page = (display || deliveryFilter || dates) ? 0 : getState().dashboard.list.activePage
            }

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/list?limit=' + limit + '&page=' + page + '&overlap=true',
                method: 'post',
                sortBy: sortBy,
                sortDirection: sortDirection,
                activePage: page,
                display: display,
                deliveryFilter: deliveryFilter ? deliveryFilter : getState().dashboard.deliveryFilter,
                accessToken: getState().authentication.accessToken,
                dates: {
                    start: startDate,
                    end: endDate
                },
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: parseInt(localStorage.getItem(getState().environment.slug + '-partnerId')),
                    sort: sortDirection,
                    page: page
                }
            }

            /** Change filters based on display **/
            switch (display) {

                case('reservation'):
                    values.params.filter = {
                        phase: 'reservation',
                        rent_start: {
                            from: moment(startDate).format('YYYY-MM-DD HH:mm'),
                            to: moment(endDate).format('YYYY-MM-DD HH:mm')
                        },
                    };
                    break;

                case('registration'):
                    values.params.filter = {
                        phase: 'registration',
                        rent_start: {
                            from: moment(startDate).format('YYYY-MM-DD HH:mm'),
                            to: moment(endDate).format('YYYY-MM-DD HH:mm')
                        },
                    };
                    break;

                case('return'):
                    values.params.filter = {
                        phase: 'note',
                        rent_end: {
                            from: moment(startDate).format('YYYY-MM-DD HH:mm'),
                            to: moment(endDate).format('YYYY-MM-DD HH:mm')
                        },
                    };
                    break;

                case('late'):
                    values.fetchUrl = ApiEnum.Api + '/rental/list?limit=' + limit + '&page=' + page;
                    values.params.filter = {
                        phase: 'note',
                        period: {
                            rent_start: '2020-01-01',
                            rent_end: moment().subtract(1, 'days').format('YYYY-MM-DD')
                        }
                    };
                    break;

                default:
                    break;
            }

            /** Change filters based on delivery status **/

            switch (deliveryFilter) {
                case('pickup'):
                    values.params.filter = {
                        ...values.params.filter,
                        delivery: false
                    }
                    break;
                case('delivery'):
                    values.params.filter = {
                        ...values.params.filter,
                        delivery: true
                    }
                    break;
                default:
                    break;
            }


            SetSortValues(values, sortBy, sortDirection, getState().searchPage)

            await ActionUtility.createThunkEffect(dispatch, DashboardAction.REQUEST_RENTALS_IN_PERIOD, DashboardEffect.requestRentalsInPeriod, values);
        }

    }

    static requestItemsInPeriod(display = false, deliveryFilter = false, sortBy = false, sortDirection = false, dates = false, page = false) {

        return async (dispatch, getState) => {

            display = display ? display : getState().dashboard.display.active
            deliveryFilter = deliveryFilter ? deliveryFilter : getState().dashboard.deliveryFilter.active

            let startDate = dates ? moment(dates[0]).startOf('day').format('YYYY-MM-DD HH:mm') : getState().dashboard.period.start
            let endDate = dates ? moment(dates[1]).endOf('day').format('YYYY-MM-DD HH:mm') : getState().dashboard.period.end

            /** RESET DATE IF THE DISPLAY HAS CHANGED **/
            if (display !== getState().dashboard.display.active) {
                startDate = moment().startOf('day').format('YYYY-MM-DD HH:mm')
                endDate = moment().endOf('day').format('YYYY-MM-DD HH:mm')
            }

            /** GET ACTIVE PAGE IF A NEW PAGE ISNT PASSED **/
            if (!page && page !== 0) {
                page = (display || deliveryFilter || dates) ? 0 : getState().dashboard.items.activePage
            }

            /** SETTING THE RIGHT URL **/
            const url = display === 'items-return' ? ApiEnum.Api + '/item/incoming?limit=5&page=' + page : ApiEnum.Api + '/item/outgoing?limit=5&page=' + page

            const values = {
                fetchUrl: url,
                method: 'post',
                sortBy: sortBy,
                sortDirection: sortDirection,
                activePage: page,
                display: display,
                deliveryFilter: deliveryFilter ? deliveryFilter : getState().dashboard.deliveryFilter,
                accessToken: getState().authentication.accessToken,
                dates: {
                    start: startDate,
                    end: endDate
                },
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: parseInt(localStorage.getItem(getState().environment.slug + '-partnerId')),
                    date_from: startDate,
                    date_to: endDate
                }
            }

            /** Change filters based on delivery status **/

            switch (deliveryFilter) {
                case('pickup'):
                    values.params.delivery = false
                    break;
                case('delivery'):
                    values.params.delivery = true
                    break;
                default:
                    break;
            }

            SetSortValues(values, sortBy, sortDirection, getState().dashboard)

            await ActionUtility.createThunkEffect(dispatch, DashboardAction.REQUEST_ITEMS_IN_PERIOD, DashboardEffect.requestItemsInPeriod, values);
        }

    }


    static requestWeeklyAvailability() {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/availability/per-day?startDate=' + moment().format('YYYY-MM-DD') + '&endDate=' + moment().add(7, 'days').format('YYYY-MM-DD') + '&partnerLocationId=' +  parseInt(localStorage.getItem(getState().environment.slug + '-partnerId')) + '&type=all&timeUnitId=3',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partnerLocationId: parseInt(localStorage.getItem(getState().environment.slug + '-partnerId')),
                    exclude_unassigned_partner_stock: true,
                    visible_in_partner_environment: true,
                    exclude_virtual_availability: true,
                    time_unit_id: 3
                }
            }


            await ActionUtility.createThunkEffect(dispatch, DashboardAction.REQUEST_WEEKLY_AVAILABILITY, DashboardEffect.requestWeeklyAvailability, values);

        }

    }

    static setRentalButton(value) {
        return async (dispatch, getState) => {

            if (getState().dashboard.rentalDashboardButtons.active === value) {
                value = null
            }

            await ActionUtility.createThunkEffect(dispatch, DashboardAction.SET_RENTAL_BUTTON, DashboardEffect.setRentalButton, value);
        };
    }

    static setPage(pageNumber) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DashboardAction.SET_PAGE, DashboardEffect.setPage, pageNumber);
        };
    }

    static reset() {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DashboardAction.RESET, DashboardEffect.reset);
        };
    }
}
