import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerTimeUnitAmount = async (args) => {
  return await useAppAxios.get(globalConfig.get().apiUrl + apiEnum.PARTNER + '/time-unit/amount', {
    headers: {
      'Authorization': `Bearer ` + localStorage.getItem('accessToken')
    },
    params: {
      ...args
    }
  }).then(function (response) {
    return response
  }).catch(function (error) {
    return error
  })
}