import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiParnterMutationCreateCategories = async (values) => {
    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/mutation/create', {
        type: 'items', // should be category, the type name is wrong since it's about categories not items
        categories: values.categoryMutations.map((categoryMutation) => {
            return {
                quantity: categoryMutation.quantity,
                category_id: categoryMutation.categoryId,
                mutation_price: categoryMutation.mutationPrice
            }
        })
    }, {
        params: {
            rental: values.rentalUuid
        }
    }).then(function (response) {

        return response

    }).catch(function (error) {
        console.log(error)

        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
