import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import {Table, TableCell, TableHeader, TableBody, DataTableCell} from '@david.kucsai/react-pdf-table'


import CustomerModel from "../../models/CustomerModel";
import DisplayName from "../../helpers/DisplayName";
import DisplayPrice from "../../helpers/DisplayEuro";
import translate from "../../../createSlice/common/helpers/utils/translate";
import {Translate} from "@material-ui/icons";
import moment from "moment";

// Create styles
const styles = StyleSheet.create({
    page: {
        fontSize: 12,
        padding: 30,
        paddingHorizontal: 30,
        lineHeight: 1.5
    },
    section: {
        margin: 0,
        padding: 10,
    },
    sectionHeader: {
        margin: 0,
        padding: 10,
        display: 'flex',
        alignContent: 'right',
        justifyContent: 'flex-end'
    },
    tabler: {
        margin: 10,
        marginHorizontal: 0
    },
    headerText: {
        fontSize: 11,
        fontWeight: 1200,
        color: "#1a245c",
        margin: 8
    },
    tableText: {
        margin: 10,
        fontSize: 10,
        color: '#000'
    },
    qr: {
        height: 'auto',
        width: 100,
    },
    logo: {
        height: 'auto',
        width: 200
    },
    tableCell: {
        border: 0,
        padding: 2,
        paddingHorizontal: 12,
        fontSize: 10,
    },
    tableHeader: {
        border: 0,
        borderColor: '#fff',
    },
    tableBody: {
        border: 0,
        borderColor: '#fff',
    }
});


// Create Document Component
const RentalPdf = (props) => {

    return (

        <Document>
            <Page size="A4" style={styles.page}>

                {props.environment.data.logo &&
                    <View style={styles.sectionHeader}>
                        <Image style={styles.logo} src={props.environment.data.logo}/>
                    </View>
                }


                <View style={styles.section}>
                    <Text>{translate('rental')} {props.rentalDetail.RentalNr}</Text>
                    <Text>{translate('bookingsPeriod')}: {props.rentalDetail.periodFrom} {translate('until')} {props.rentalDetail.periodUntil}</Text>
                </View>

                <View style={styles.section}>
                    <Text>{translate('customerData')}:</Text>

                    {!!props.rentalDetail.data.customer_private_person &&
                        <Customer
                            data={props.rentalDetail.data.customer_private_person}
                        />
                    }

                    {!!props.rentalDetail.data.customer_company &&
                        <Company
                            data={props.rentalDetail.data.customer_company}
                            contactPersons={props.rentalDetail.data.customer_company_contacts}
                        />
                    }

                    {!!props.rentalDetail.holidayAddress &&
                        <Text>{translate('holidayAddress')}: {props.rentalDetail.holidayAddress}</Text>
                    }

                </View>

                <View style={styles.section}>
                    <Text>{translate('yourBicyclesAndAccessoires')}</Text>
                    <Totals rentalTotals={props.rentalTotals} rentalDetail={props.rentalDetail} overrideItems={props.rentalDetail.RentalItems}/>
                </View>

                {!!props.rentalIssue.items && props.rentalIssue.items.length > 0 &&
                    <View style={styles.section}>
                        <Text>{translate('bicyclesProvidedToYou')}</Text>
                        <IssuedItems items={props.rentalIssue.items}/>
                    </View>
                }

                {!!props.rentalDetail.data.qr_url &&
                    <View style={styles.section}>
                        <Text>{translate('rentalCodeQr')}</Text>
                        <Image style={styles.qr} src={props.rentalDetail.data.qr_url}/>
                    </View>
                }

                {props.rentalDetail.data.rental.client_id === 3 &&
                    <View wrap={false}>
                        <View style={styles.section}>
                            <Text><b>{translate('agreementTitle')}</b></Text>
                            <Text>{translate('agreementText')}</Text>
                        </View>

                        <View style={styles.section}>
                            <Text>{translate('signature')}_______________________________ {translate('date') + ': ' + moment().format("DD-MM-YYYY")}</Text>
                        </View>
                    </View>
                }

            </Page>
        </Document>
    )
};

const Customer = (props) => {

    const customer = new CustomerModel(props.data)

    return (
        <>
            {!!customer.data.name &&
                <Text>{translate('name')}: {customer.data.name}</Text>
            }

            {!!customer.data.telephone &&
                <Text>{translate('phoneNumber')}: {customer.data.telephone}</Text>
            }

            {!!customer.data.email &&
                <Text>{translate('emailAddress')}: {customer.data.email}</Text>
            }
        </>
    )

}

const Company = (props) => {

    const customer = props.data
    const contactPersons = props.contactPersons

    return (
        <>
            {!!customer.name &&
                <Text>{translate('name')}: {customer.name}</Text>
            }

            {!!customer.primary_phone &&
                <Text>{translate('phoneNumber')}: {customer.primary_phone}</Text>
            }

            {!!customer.primary_email &&
                <Text>{translate('emailAddress')}: {customer.primary_email}</Text>
            }

            {!!customer.address &&
                <Text>{translate('Address')}: {customer.address}</Text>
            }

            {contactPersons && contactPersons.map((contactPerson) => {
                return (
                    <View>
                        <Text>{translate('contactPerson')}</Text>
                        <Text>{'name'}: {DisplayName(contactPerson)}</Text>
                        {!!contactPerson.email && <Text>Email: {contactPerson.email}</Text>}
                        {!!contactPerson.phone && <Text>{translate('phoneNumber')}: {contactPerson.phone}</Text>}
                    </View>
                )
            })}

        </>
    )

}

const Totals = (props) => {


    if (props.rentalTotals && props.rentalTotals.totals) {


        if (props.overrideItems) {
            props.rentalTotals.totals.RentalItems = props.overrideItems
        }

        return (

            <>

                <View style={styles.tabler}>
                    <Table data={props.rentalTotals.totals.RentalItems}>
                        <TableHeader style={styles.tableHeader}>
                            <TableCell style={styles.tableCell}>
                                Item(s)
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                {translate('price')}
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                {translate('amount')}
                            </TableCell>
                            <TableCell style={styles.tableCell}>
                                {translate('totals')}
                            </TableCell>
                        </TableHeader>
                        <TableBody style={styles.tableBody}>
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.category}/>
                            <DataTableCell style={styles.tableCell} getContent={(r) => DisplayPrice(r.data.price)}/>
                            <DataTableCell style={styles.tableCell} getContent={(r) => r.data.quantity + 'x'}/>
                            <DataTableCell style={styles.tableCell}
                                           getContent={(r) => DisplayPrice(r.data.totalPrice)}
                            />
                        </TableBody>
                    </Table>

                </View>

                {!!props.rentalTotals.totals.data.totalDiscount &&
                    <Text>Korting op totaalprijs: {DisplayPrice(props.rentalTotals.totals.data.totalDiscount)}</Text>}
                {!!props.rentalTotals.totals.data.totalDeposit &&
                    <Text>{translate('totalDepositPrice')}: {DisplayPrice(props.rentalTotals.totals.data.totalDeposit)}</Text>
                }
                <Text>{translate('totalIncVAT')}: {DisplayPrice(props.rentalTotals.totals.data.totalPrice)}</Text>

                {!!props.rentalTotals.totals.data.totalPricePaid &&
                    <Text>{translate('alreadyPaid')}: {DisplayPrice(parseFloat(props.rentalTotals.totals.data.totalPricePaid) + parseFloat(props.rentalTotals.totals.data.totalDepositPaid))}</Text>
                }

                {!!props.rentalDetail.data.paymentStatus === 'open' &&
                <Text>{translate('Openstaand bedrag')}: {DisplayPrice(props.rentalTotals.totals.data.totalPriceRest)}</Text>
                }

            </>
        )


    } else return null


}

const IssuedItems = (props) => {
    if (props.items) {

        return (
            <View style={styles.tabler}>
                <Table data={props.items} style={styles.table}>
                    <TableHeader style={styles.tableHeader}>
                        <TableCell style={styles.tableCell}>
                            Product code (nieuw)
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            Product code (oud)
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                            Categorie
                        </TableCell>
                    </TableHeader>
                    <TableBody style={styles.tableBody}>
                        <DataTableCell style={styles.tableCell} getContent={(r) => r.product_code}/>
                        <DataTableCell style={styles.tableCell} getContent={(r) => r.product_code_prev}/>
                        <DataTableCell style={styles.tableCell} getContent={(r) => r.category.name}/>
                    </TableBody>
                </Table>

            </View>
        )


    } else return null
}


export default RentalPdf;
