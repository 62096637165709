// Dependencies
import React from 'react'
import {connect} from "react-redux";

// Actions
import EnvironmentAction from "../../stores/environment/EnvironmentAction";

// Components
import AvailableCategories from "./components/AvailableCategories";

import ItemAction from "../../stores/item/ItemAction";
import Scanner from "../../common/components/General/Scanner";

const mapStateToProps = (state) => ({
    environment: state.environment,
    depotModal: state.depotModal,
    serviceModal: state.serviceModal
});

class Stock extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            popup: {
                show: false,
                item: null,
                loading: false
            },
            activeCategoryId: null,
            categoryLoading: false
        }
    }

    componentDidMount() {
        this.load()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.depotModal !== this.props.depotModal || (prevProps.serviceModal.item !== this.props.serviceModal.item)) {
            this.props.dispatch(ItemAction.requestAvailableCategorylist()).then(() => {
                this._requestCategoryItems(this.state.activeCategoryId)
            })

        }


    }


    load() {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(ItemAction.requestAvailableCategorylist()).then(() => {

                this.props.dispatch(EnvironmentAction.setLoader(false))
            })
        })
    }

    render() {
        return (
            <main style={{padding: '2rem 0'}}>

                <Scanner type={'rental'}/>

                <div className="o-container o-container--primary">
                    <AvailableCategories
                        {...this.state}
                        history={this.props.history}
                        setActiveCategoryId={this._setActiveCategoryId}
                    />
                </div>

            </main>
        )
    }

    _setActiveCategoryId = (id) => {

        if (this.state.activeCategoryId === id) {
            id = null
        }

        this.setState({
            activeCategoryId: id,
        })

        this._requestCategoryItems(id)

    }

    _requestCategoryItems = (id) => {

        this.setState({
            categoryLoading: true
        })

        if (id) {
            this.props.dispatch(ItemAction.requestAvailableItemList({category: id})).then(() => {
                this.props.dispatch(ItemAction.formatStockItemsByCategory()).then(() => {
                        this.setState({
                            categoryLoading: false
                        })
                    }
                )
            })

        } else {
            this.setState({
                categoryLoading: false
            })
        }
    }
}

export default connect(mapStateToProps)(Stock);
