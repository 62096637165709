import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerMutationHanldeInsurance = async (values) => {
    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/mutation/create', {
        type: 'insurance', // should be category, the type name is wrong since it's about categories not items
        insurance_id: values.insuranceId
    }, {
        params: {
            rental: values.rentalUuid
        }
    }).then(function (response) {

        return response

    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
