import React from 'react'
import {Link} from "react-router-dom";
import RouteEnum from "../../../common/constants/RouteEnum";
import GetContrast from "../../../common/helpers/GetContrast";
import DashboardAction from "../../../stores/dashboard/DashboardAction";
import CheckDisplayModule from "../../../common/helpers/CheckDisplayModule";
import {toggleSearchBar} from "../../../createSlice/features/pages/searchPage/searchPageSlice";
import translate from "../../../createSlice/common/helpers/utils/translate";

const DashboardButtons = (props) => {

    const {rentalDashboardButtons, environment} = props
    const {primary_color_light} = environment.data.info
    const primaryColorContract = GetContrast(primary_color_light)

    return (
        <div className="c-icon-blocks s-dashboard-blocks">

            {CheckDisplayModule('rentalCreate', props.environment, props.partnerUser) &&
            <Link
                className="c-icon-blocks__single"
                to={'/' + environment.slug + RouteEnum.RentalCreate}
                style={{background: primary_color_light}}
            >

                <i style={{color: primaryColorContract}} className="icon icon-add"/>

                <h2 style={{color: primaryColorContract}}>{translate('newRental')}</h2>

                <div style={{backgroundColor: primaryColorContract}}>
                    <small style={{color: primary_color_light}}>F1</small>
                </div>

            </Link>
            }

            {CheckDisplayModule('rentalMutations', props.environment, props.partnerUser) && rentalDashboardButtons.options.map((button) => {

                let className = 'c-icon-blocks__single'

                if (button.disabled) {
                    className += ' is-disabled'
                }

                if (button.value === rentalDashboardButtons.active) {
                    className += ' is-active'
                }

                const iconClassName = 'icon icon-' + button.icon

                return (
                    <button
                        key={'dashboard-button-' + button.value}
                        className={className}
                        style={{background: primary_color_light}}
                        onClick={() => {
                            clickRentalButton(button.value)
                        }}>

                        <i className={iconClassName} style={{color: primaryColorContract}}/>

                        <h2 style={{color: primaryColorContract}}>{button.label}</h2>

                        <div style={{backgroundColor: primaryColorContract}}>
                            <small style={{color: primary_color_light}}>{button.keyPress}</small>
                        </div>

                    </button>
                )
            })}

            <button
                className="c-icon-blocks__single"
                style={{background: primary_color_light}}
                onClick={() => {
                    props.dispatch(toggleSearchBar())
                }}
            >

                <i className="icon icon-search" style={{color: primaryColorContract}}/>

                <h2 style={{color: primaryColorContract}}>{translate('search')}</h2>

                <div style={{backgroundColor: primaryColorContract}}>
                    <small style={{color: primary_color_light}}>F6</small>
                </div>

            </button>

        </div>
    )

    function clickRentalButton(value) {
        props.dispatch(DashboardAction.setRentalButton(value))
    }


}

export default DashboardButtons