import moment from "moment";
import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalTotalsEffect from "../totals/RentalTotalsEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import MapValuesAsGetParameters from "../../../common/helpers/MapValuesAsGetParameters";
import MapIssuesToCategories from "../../../common/helpers/MapIssuesToCategories";
import CalculateSwapToCategoryRequest from "../../../common/helpers/CalculateSwapToCategoryRequest";
import {apiPartnerTimeUnitList} from "../../../createSlice/common/api/partner/timeUnit/apiPartnerTimeUnitList";


export default class RentalTotalsAction {

    static REQUEST_TOTALS = 'RentalTotalsAction.REQUEST_TOTALS';

    static SET_DISCOUNT_PERCENTAGE = 'RentalTotalsAction.SET_DISCOUNT';
    static SET_DISCOUNT_PRICE = 'RentalTotalsAction.SET_DISCOUNT_PRICE';
    static SET_DISCOUNT_TYPE = 'RentalTotalsAction.SET_DISCOUNT_TYPE';

    static LOAD_DISCOUNT_FROM_INFO = 'RentalTotalsAction.LOAD_DISCOUNT_FROM_INFO';
    static LOAD_DEPOSIT_FROM_INFO = 'RentalTotalsAction.LOAD_DEPOSIT_FROM_INFO';
    static SET_DEPOSIT = 'RentalTotalsAction.SET_DEPOSIT';
    static SUBMIT_DEPOSIT = 'RentalTotalsAction.SUBMIT_DEPOSIT';
    static SUBMIT_DISCOUNT = 'RentalTotalsAction.SUBMIT_DISCOUNT';

    static RESET = 'RentalTotalsAction.RESET';

    static requestTotals(create = false, insurancePreview = false, deliveryPreview = false, useRentalId = false, rentalExtend = false) {

        return async (dispatch, getState) => {

            let params = {
                rental: false,
                auth_token: localStorage.getItem('authToken'),
                partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.startTime,
                endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD') + ' ' + getState().rentalPeriod.period.endTime,
                insurance_id: null,
                deposit: getState().rentalTotals.deposit ? 1 : 0,
                time_unit_id: getState().rentalPeriod.period.chosenUnit
            }


            if (useRentalId) {
                params = {
                    rental: false,
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    startDate: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD'),
                    endDate: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD'),
                    insurance_id: null,
                    deposit: getState().rentalTotals.deposit ? 1 : 0,
                    rentalId: getState().rentalDetail.data.data.rental.uuid
                }
            }

            if (insurancePreview || create || getState().insurances.selected) {

                if (getState().insurances.selected) {
                    params.insurance_id = getState().insurances.selected
                } else if (create && getState().insurances.default_insurance_id) {
                    params.insurance_id = getState().insurances.default_insurance_id
                }

            }

            if (getState().rentalTotals.discountType === 'fixed') {
                params.discountPrice = getState().rentalTotals.discountPrice + ''.replace('.', ',')
            }

            if (getState().rentalTotals.discountType === 'percentage') {
                params.discountPercentage = getState().rentalTotals.discountPercentage + ''.replace('.', ',')
            }

            if (getState().rentalDetail.data && getState().rentalDetail.data.data && getState().rentalDetail.data.data.rental.uuid) {
                params.rental = getState().rentalDetail.data.data.rental.uuid
            }

            if (create && getState().rentalPeriod.period.startDate === moment().format('YYYY-MM-DD')) {
                params.categories = MapIssuesToCategories(getState().rentalIssue.items)
            } else {
                params.categories = Object.values(getState().rentalItems.shoppingCart.bicycles).concat(Object.values(getState().rentalItems.shoppingCart.accessoires)).map((category) => {
                    return {
                        ...category,
                        category_id: category.id
                    }
                })
            }

            if (deliveryPreview) {

                params.delivery = !!getState().rentalDelivery.delivery.enabled ? 1 : 0

                if (params.delivery) {
                    params.delivery_location_id = getState().rentalDelivery.delivery.deliveryPickupLocation.address.id
                }

            }

            // In case an extension is in place. Submit that as last date
            if (getState().rentalExtend.newDate) {

                const response = await apiPartnerTimeUnitList().then((response) => {
                    return response
                })

                const timeUnit = response.data.items.data.find((timeUnitItem) => timeUnitItem.type.code === 'days');

                params.time_unit_id = timeUnit.id;
                params.endDate = moment(getState().rentalExtend.newDate).format('YYYY-MM-DD HH:mm')

            }

            const urlParams = MapValuesAsGetParameters(params)


            const values = {
                fetchUrl: ApiEnum.Api + '/rental/totals' + urlParams,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: params
            }

            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.REQUEST_TOTALS, RentalTotalsEffect.requestTotals, values);
        };
    }

    static setDiscountPercentage(percentage) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SET_DISCOUNT_PERCENTAGE, RentalTotalsEffect.setDiscountPercentage, percentage);
        };
    }

    static setDiscountPrice(percentage) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SET_DISCOUNT_PRICE, RentalTotalsEffect.setDiscountPrice, percentage);
        };
    }

    static setDiscountType(value) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SET_DISCOUNT_TYPE, RentalTotalsEffect.setDiscountType, value);
        };
    }

    static setDeposit(percentage) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SET_DEPOSIT, RentalTotalsEffect.setDeposit, percentage);
        };
    }

    static submitDeposit(uuid, force = false) {
        return async (dispatch, getState) => {

            let url = ApiEnum.Api + '/rental/add-deposit/?uuid=' + uuid

            if (!getState().rentalTotals.deposit && !force) {
                url = ApiEnum.Api + '/rental/remove-deposit/?uuid=' + uuid
            }

            const values = {
                fetchUrl: url,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    auth_token: localStorage.getItem('authToken')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SUBMIT_DEPOSIT, RentalTotalsEffect.submitDeposit, values);
        };
    }

    static submitDiscount() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    language: 'nl',
                    type: 'discount'
                }
            }

            if (getState().rentalTotals.discountType === 'fixed') {
                values.params.discount_price = getState().rentalTotals.discountPrice
            }

            if (getState().rentalTotals.discountType === 'percentage') {
                values.params.discount_percentage = getState().rentalTotals.discountPercentage
            }


            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.SUBMIT_DISCOUNT, RentalTotalsEffect.submitDiscount, values);
        };
    }

    static loadDiscountFromInfo() {
        return async (dispatch, getState) => {
            if (getState().rentalDetail) {
                const rental = getState().rentalDetail.data
                await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.LOAD_DISCOUNT_FROM_INFO, RentalTotalsEffect.loadDiscountFromInfo, rental);
            }
        };
    }

    static loadDepositFromInfo() {
        return async (dispatch, getState) => {
            if (getState().rentalDetail && getState().rentalDetail.data && getState().rentalDetail.data.data) {
                const deposit = getState().rentalDetail.data.data.rental.deposit_enabled
                await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.LOAD_DEPOSIT_FROM_INFO, RentalTotalsEffect.setDeposit, deposit);
            }
        };
    }


    static reset(number) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalTotalsAction.RESET, RentalTotalsEffect.reset, number);
        };
    }

}
