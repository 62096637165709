import RentalModel from "../../../common/models/RentalModel";
import fetchHelper from "../../../common/helpers/fetchHelper";

export default class RentalListEffect {

    static async list(values, dispatch) {

        const response = await fetchHelper(values, dispatch)

        if (response) {

            const data = Object.values(response.items.data).map((item) => {
                return new RentalModel(item)
            }).sort((a, b) => (a.data.order > b.data.order) ? 1 : -1)

            return {
                data: data,
                pages: response.items.pages,
                sortBy: values.sortBy,
                sortDirection: values.sortDirection
            }
        } else return null

    }

    static setPage(pageNumber) {
        return pageNumber;
    }

    static setLoading(value) {
        return value;
    }

    static async setSort(values) {
        return values
    }

    static async setFilter(values) {
        return values
    }

    static reset() {
        return true;
    }


}

