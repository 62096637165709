import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerAvailabilityPerCategory = async (body, params) => {

    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/availability/per-category', {
        visible_in_partner_environment: body.visibleInPartnerEnvironment
    }, {
        params: {
            ...params,
        }
    }).then(function (response) {
        if (response.data.items) {
            response.data.items = Object.values(response.data.items)

        } else {
            response.data.items = []
        }

        return response
    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
