import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {decamelizeKeys} from "humps";
import {globalConfig} from "../../../../../configuration/config";


export const apiPartnerRentalTotals = async (values) => {

    const categories = values.categories?.filter((category) => category.quantity !== 0).map((category) => {
        return decamelizeKeys(category)
    })

    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/rental/totals', {
        startDate: values.startDate,
        endDate: values.endDate,
        deposit: values.deposit,
        categories: categories,
        discountPercentage: values.discountPercentage,
        discountPrice: values.discountPrice,
        insuranceId: values.insuranceId
    }, {
        params: {
            rental: values.uuid
        }
    }).then(function (response) {

        // Clean rental items
        if (response.data.items.rentalItems) {
            response.data.items.rentalItems = Object.values(response.data.items.rentalItems)
        }

        return response

    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
