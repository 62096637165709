import React from 'react'
import {connect} from "react-redux";
import InsurancesAction from "../../../stores/insurance/list/InsurancesAction";
import RentalTotalsAction from "../../../stores/rental/totals/RentalTotalsAction";
import DisplayPrice from "../../helpers/DisplayEuro";
import translate from "../../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    ...state,
    insuranceItems: state.insurances.data,
});

class RentalInsurance extends React.Component {

    componentDidMount() {
        this.props.dispatch(InsurancesAction.list(this.props.rentalContainer)).then(() => {

            if (this.props.insurances.insurance_required && this.props.insuranceItems.length > 0) {
                if (this.props.insurances.default_insurance_id) {
                    this.props.dispatch(InsurancesAction.setInsurance(this.props.insurances.default_insurance_id)).then(() => {
                        this.props.dispatch(RentalTotalsAction.requestTotals(true, true))
                    })
                } else {
                    this.props.dispatch(InsurancesAction.setInsurance(this.props.insuranceItems[0].data.id)).then(() => {
                        this.props.dispatch(RentalTotalsAction.requestTotals(true, true))
                    })
                }
            } else {
                this.props.dispatch(InsurancesAction.setInsurance(0)).then(() => {
                    this.props.dispatch(RentalTotalsAction.requestTotals(true, true))
                })
            }

            if (this.props.rentalContainer) {

                const rentalInsurance = this.props.rentalContainer.rentalItems.find((rentalItem) => rentalItem.type === 'insurance')

                if (rentalInsurance) {
                    this.props.dispatch(InsurancesAction.setInsurance(rentalInsurance.insuranceId))
                }

            }

        })
    }


    render() {

        if (this.props.insuranceItems && this.props.insuranceItems.length > 0) {
            return (
                <div className="c-info-blocks__row">
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('insurance')}</h2>
                        </header>
                        <div className="c-info-blocks__single__body">
                            <div className="c-radio-block">

                                {this.props.insuranceItems.map((item) => (

                                    <div
                                        className={"c-radio-block__single " + (this.props.insurances.selected === item.data.id ? 'isActive' : '')}>
                                        <label htmlFor={'ins' + item.data.id}>
                                            <div className="c-radio-block__single__text">
                                                <input data-hj-allow type="radio" id={'ins' + item.data.id}
                                                       value={item.data.id}
                                                       checked={this.props.insurances.selected === item.data.id}
                                                       onChange={(e) => this._onChangeInsurance(e)}/>
                                                <div>
                                                    <h3>{item.data.name}</h3>
                                                    <p>{item.data.more_info}</p>
                                                </div>
                                            </div>
                                            <div className="c-radio-block__single__cta">
                                                <span>{DisplayPrice(item.data.price)}</span>
                                                <small>{translate('incTax')}</small>
                                            </div>
                                        </label>
                                    </div>

                                ))}

                                {!this.props.insurances.insurance_required &&
                                    <div
                                        className={"c-radio-block__single " + (this.props.insurances.selected === 0 ? 'isActive' : '')}>
                                        <label htmlFor="ins0">
                                            <div className="c-radio-block__single__text">
                                                <input data-hj-allow type="radio" id="ins0" value={0}
                                                       checked={this.props.insurances.selected === 0}
                                                       onChange={(e) => this._onChangeInsurance(e)}/>
                                                <div>
                                                    <h3>{translate('noInsurance')}</h3>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null
        }

    }

    _onChangeInsurance = (e) => {

        this.props.dispatch(InsurancesAction.setInsurance(e.currentTarget.value)).then(() => {

            /** todo: quickfix */
            if (!this.props.rentalContainer) {
                this.props.dispatch(RentalTotalsAction.requestTotals(true, true, true)) // only on create
            }
        })

    }

}

export default connect(mapStateToProps)(RentalInsurance);
