import {RentalOrderLinesToGive} from "../../../../common/components/RentalOrderLinesToGive";

import {
    giveItem,
    setCategoryMutation,
    validateGiveItems
} from "../rentalItemsExchangePageSlice";
import {ScannedItems} from "../../../../common/components/ScannedItems";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {useEffect} from "react";
import {RentalItemNotifications} from "../../../../common/components/RentalItemNotifications";
import Loader from "../../../../../views/Loader";

export const RentalItemsExchangeGiveStep = (props) => {

    const dispatch = useAppDispatch()

    const {
        rentalContainer,
        rentalItems,
        categoryMutations,
        rentalTakeItems,
        rentalGiveItems,
        giveItemValidation,
        step,
        rentalGiveItemNotifications,
        occupiedItems,
    } = useAppSelector(store => store.rentalItemsExchangePage)


    useEffect(() => {
        if (step === 'give') {
            dispatch(validateGiveItems())
        }
    }, [rentalGiveItems.length, step])


    if (rentalContainer) {


        let containerClass = ''

        if (props.disabled) {
            containerClass += ' is-disabled'
        }

        return (
            <>
                <div className={'c-swap-container'}>

                    <div className={containerClass}>

                        <h2>2. Uit te geven items</h2>

                        <RentalOrderLinesToGive
                            rentalOrderLines={rentalContainer.rentalItems}
                            categoryMutations={categoryMutations}
                            giveItems={rentalGiveItems}
                            takeItems={rentalTakeItems}
                            rentalItems={rentalItems}
                            mutateCategoryAmountAction={(categoryMutation) => dispatch(setCategoryMutation(categoryMutation))}
                            disableManualCategoryMutations={true}
                        />

                        <ScannedItems
                            title={'Scan de items in die omgeruild dienen te worden'}
                            scanItemAction={(item) => dispatch(giveItem({item: item, scanned: true}))}
                            removeItemAction={(item) => dispatch(giveItem({item: item}))}
                            items={rentalGiveItems}
                            validation={true}
                            lockedItems={rentalItems.filter((rentalItem) => !rentalTakeItems.find((rentalTakeItem) => rentalTakeItem.id === rentalItem.id))}
                            validating={giveItemValidation.processing}
                            disabled={props.disabled}
                            notifications={rentalGiveItemNotifications}
                            itemScanner={true}
                        />

                        {!!occupiedItems.length &&
                        <ScannedItems
                            title={'Reeds gekoppeld aan bestaande boeking'}
                            scanItemAction={(item) => dispatch(giveItem({item: item, scanned: true}))}
                            removeItemAction={(item) => dispatch(giveItem({item: item}))}
                            items={occupiedItems}
                            validation={true}
                            validating={giveItemValidation.processing}
                            disabled={props.disabled}
                            notifications={rentalGiveItemNotifications}
                            displayRentalInfo={true}
                            itemScanner={false}
                        />
                        }

                        <RentalItemNotifications notifications={rentalGiveItemNotifications}/>

                        {giveItemValidation.processing &&
                        <div className={'c-processing'} style={{marginBottom: '1.5rem'}}>

                            <Loader
                                height="20"
                                width="20"
                                color='#002BD3'
                                ariaLabel='loading'
                            />

                            <p style={{marginLeft: '1rem'}}> Items worden gevalideerd</p>

                        </div>
                        }

                    </div>

                </div>

            </>
        )
    } else return null

}
