import React from 'react'
import {AutoSizer, Column, SortDirection, Table} from "react-virtualized";
import RouteEnum from "../../../common/constants/RouteEnum";
import TinyLoader from "../../../common/components/General/TinyLoader";
import ReactPaginate from 'react-paginate';
import DashboardAction from "../../../stores/dashboard/DashboardAction";
import translate from "../../../createSlice/common/helpers/utils/translate";

const RentalTable = (props) => {

    const pages = props.list.pages
    const loading = props.listLoading

    return (
        <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll list-loading-' + loading}>
            <div className="c-table c-table-standard c-table--dashboard c-Rental-overview">

                {props.list.data &&

                <>
                    <AutoSizer>
                        {({width}) => (

                            <Table
                                width={width}
                                height={40 * (props.list.data.length + 1)}
                                headerHeight={40}
                                rowHeight={40}
                                rowCount={props.list.data.length}
                                rowStyle={rowStyleFormat.bind(this)}
                                rowGetter={({index}) => props.list.data.sort((a, b) => (a.data.order > b.data.order) ? 1 : -1)[index]}
                                className={'c-table'}
                                onRowClick={({event, index, rowData}) => {
                                    clickRental(rowData)
                                }}
                                sort={sort}
                                sortBy={props.sortBy}
                                sortDirection={props.sortDirection}
                            >

                                <Column label="Boekings nr" dataKey="RentalNr" width={width * 0.125}/>


                                <Column label={translate('from')} dataKey="periodFrom" width={width * 0.10}/>
                                <Column label={translate('until')} dataKey="periodUntil" width={width * 0.10}/>

                                {props.rentalNotation?.code === 'day' &&
                                <Column label={translate('time')} dataKey="time" width={width * 0.10}/>
                                }

                                <Column label={translate('renter')} dataKey="renter" width={width * 0.125}/>
                                <Column label={translate('amount')} dataKey="amount" width={width * 0.067}
                                        disableSort={true}/>
                                <Column label={translate('stay')} dataKey="holidayAddress" width={width * 0.125}
                                        disableSort={true}/>
                                <Column label={translate('totalPrice')} dataKey="totalPrice" width={width * 0.125}
                                        disableSort={true}/>
                                <Column label={translate('paymentStatus')} dataKey="paymentStatus"
                                        width={width * 0.125} disableSort={true}/>
                                <Column label="Status" dataKey="status" width={width * 0.125}
                                        disableSort={true}/>

                            </Table>)}
                    </AutoSizer>

                    {props.list.data && props.list.data.length === 0 &&
                    <p style={{textAlign: 'center', padding: '2rem', fontSize: '1.2rem'}}>{translate('noRentalsInFiltering')}</p>
                    }

                    {!!pages &&
                    <ReactPaginate
                        previousLabel={'< ' + translate('previous')}
                        nextLabel={translate('next') + ' >'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pages.total}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        forcePage={parseInt(pages.current)}
                        activeClassName={'active'}
                    />
                    }
                </>

                }

                {!props.list.data && <TinyLoader/>}


            </div>
        </div>

    )

    function clickRental(rowData) {
        switch (props.rentalDashboardButtons.active) {
            case('extend'):
                props.history.push('/' + props.environment.slug + RouteEnum.RentalExtend + '/' + rowData.data.rental.uuid)
                break;
            case('period'):
                props.history.push('/' + props.environment.slug + RouteEnum.RentalPeriod + '/' + rowData.data.rental.uuid)
                break;
            case('swap'):
                props.history.push('/' + props.environment.slug + RouteEnum.RentalSwapItems + '/' + rowData.data.rental.uuid)
                break;
            case('issue'):
                props.history.push('/' + props.environment.slug + RouteEnum.RentalIssueItems + '/' + rowData.data.rental.uuid)
                break;
            case('return'):
                props.history.push('/' + props.environment.slug + RouteEnum.RentalReturnItems + '/' + rowData.data.rental.uuid)
                break;
            default:
                props.history.push('/' + props.environment.slug + RouteEnum.RentalDetail + '/' + rowData.data.rental.uuid)
                break;
        }

    }

    function sort({sortBy, sortDirection}) {

        const {sortDirection: prevSortDirection} = props;

        if (prevSortDirection === SortDirection.DESC) {
            sortBy = null;
            sortDirection = null;
        }


        props.dispatch(DashboardAction.requestRentalsInPeriod(false, false, sortBy, sortDirection))
    }

    function handlePageClick(i) {
        props.dispatch(DashboardAction.requestRentalsInPeriod(false, false, false, false, false, i.selected))
    }

    function rowStyleFormat(row) {

        const {environment, list} = props
        const item = list.data[row.index]

        if (item) {

            if (item.data.rental.affiliate_partner_id && item.data.rental.affiliate_partner_id !== environment.data.info.partner_id) {
                return {
                    background: '#bfbfff'
                }
            }
        }

        return null
    }

}

export default RentalTable