import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalExtendEffect from "./RentalExtendEffect";
import moment from 'moment'
import RentalPeriodEffect from "../period/RentalPeriodEffect";
import ApiEnum from "../../../common/constants/ApiEnum";
import MapValuesAsGetParameters from "../../../common/helpers/MapValuesAsGetParameters";
import {apiPartnerTimeUnitList} from "../../../createSlice/common/api/partner/timeUnit/apiPartnerTimeUnitList";

export default class RentalExtendAction {

    static SET_NEW_DATE = 'RentalExtendAction.SET_NEW_DATE';
    static CHECK_AVAILABILITY = 'RentalExtendAction.CHECK_AVAILABILITY';
    static SUBMIT_MUTATION = 'RentalExtendAction.SUBMIT_MUTATION';
    static REQUEST_TOTALS = 'RentalExtendAction.REQUEST_TOTALS';
    static RESET = 'RentalExtendAction.RESET';


    static setNewDate(date) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalExtendAction.SET_NEW_DATE, RentalExtendEffect.setNewDate, date);
        }

    }

    static checkAvailability() {

        return async (dispatch, getState) => {

            const bicycleParams = {
                startDate: moment(getState().rentalDetail.data.data.rental.date_rent_end).add('1', 'days').format('YYYY-MM-DD'),
                endDate: moment(getState().rentalExtend.newDate).format('YYYY-MM-DD'),
                auth_token: localStorage.getItem('authToken'),
                partnerLocationId: localStorage.getItem(getState().environment.slug + '-partnerId'),
                visible_in_partner_environment: true
            }

            if (getState().environment.data.rental_notation?.code === 'day') {
                bicycleParams.startDate += ' ' + getState().rentalPeriod.time
                bicycleParams.endDate += ' ' + getState().rentalPeriod.time
            }

            if (getState().rentalDetail) {
                bicycleParams.rentalId = getState().rentalDetail.data.data.rental.uuid
            }

            const accessoireParams = {
                ...bicycleParams,
                type: 'accessoire'
            }

            const values = {
                shoppingCartItems: getState().rentalDetail.data.data.rentalItems,
                bicycles: {
                    fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(bicycleParams),
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: bicycleParams,
                },
                accessoires: {
                    fetchUrl: ApiEnum.Api + '/availability/per-category' + MapValuesAsGetParameters(accessoireParams),
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: accessoireParams,
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalExtendAction.CHECK_AVAILABILITY, RentalPeriodEffect.checkAvailability, values);
        }

    }

    static submitMutation() {

        return async (dispatch, getState) => {

            const response = await apiPartnerTimeUnitList().then((res) => {
                return res;
            })

            const timeUnit = response.data.items.data.find((timeUnitItem) => timeUnitItem.type.code === 'days');

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    date_start: moment(getState().rentalDetail.data.data.rental.date_rent_start).format('YYYY-MM-DD HH:mm'),
                    date_end: moment(getState().rentalExtend.newDate).format('YYYY-MM-DD HH:mm'),
                    time_unit_id: timeUnit.id,
                    type: 'extend'
                }
            }

            // NOTE: Effect is from the period store.
            await ActionUtility.createThunkEffect(dispatch, RentalExtendAction.SUBMIT_MUTATION, RentalExtendEffect.submitMutation, values);
        }

    }

    static reset() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalExtendAction.RESET, RentalExtendEffect.reset);
        }

    }


}
