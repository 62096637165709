import React from 'react'
import {connect, useSelector} from "react-redux";
import DashboardWrapper from "../common/wrappers/DashboardWrapper";
import {Route, Switch} from "react-router";
import RouteEnum from "../common/constants/RouteEnum";
import "react-datepicker/dist/react-datepicker.css";
import DashboardOverview from "./Dashboard";
import FunctionKeys from "../common/components/General/FunctionKeys";
import TimeTicker from "../common/components/General/TimeTicker";
import SearchBar from "../common/components/General/SearchBar";
import ScanItems from "./ScanItems";
import UserAction from "../stores/user/UserAction";
import Scanner from "../common/components/General/Scanner";
import Stock from "./Stock";
import TransactionOverview from "./Transaction/TransactionOverview";
import LocationAction from "../stores/location/LocationAction";
import RentalDetail from "./RentalDetail";
import RentalReturnItems from "./RentalReturnItems";
import RentalCreate from "./RentalCreate";
import RentalCancellation from "./RentalCancellation";
import RentalItems from "./RentalCategories";
import RentalAddress from "./RentalCustomerData";
import RentalDeliveryMutation from "./RentalDelivery";
import RentalPeriodMutation from "./RentalPeriod";
import TransactionCheck from "./Transaction/components/Check";
import TransactionStatus from "./Transaction/components/Status";
import Transaction from "./Transaction";
import ItemsLate from "./ItemsLate/ItemsLate";
import Availability from "./Availability/Availability";
import DisplayModuleByEnvironment from "../common/helpers/DisplayModuleByEnvironment";
import DepotModalContainer from "./DepotModal/DepotModalContainer";
import {
    RentalItemsGivePageContainer
} from "../createSlice/features/pages/rentalItemsGivePage/RentalItemsGivePageContainer";
import ServiceModalContainer from "./serviceModal/ServiceModalContainer";
import {ProductRentalsPageContainer} from "./productRentalsPage/ProductRentalsPageContainer";
import {ProductDetailPageContainer} from "../createSlice/features/pages/productDetailPage/ProductDetailPageContainer";
import SoldModalContainer from "./soldModal/SoldModalContainer";
import UnlinkItemModalContainer from "../createSlice/features/modals/unlinkItemModal/UnlinkItemModalContainer";
import {
    RentalItemsExchangePageContainer
} from "../createSlice/features/pages/rentalItemsExchangePage/RentalItemsExchangePageContainer";
import {
    RentalOverviewPageContainer
} from "../createSlice/features/pages/rentalOverviewPage/RentalOverviewPageContainer";
import {SettingsPageContainer} from "../createSlice/features/pages/settingsPage/SettingsPageContainer";
import {EventScanPageContainer} from "../createSlice/features/pages/EventScanPage/EventScanPageContainer";
import {EventDemandPageContainer} from "../createSlice/features/pages/EventDemandPage/EventDemandPageContainer";
import {SearchPageContainer} from "../createSlice/features/pages/searchPage/SearchPageContainer";
import StolenModalContainer from "../createSlice/features/modals/stolenModal/StolenModalContainer";
import {
    RentalInsurancePageContainer
} from "../createSlice/features/pages/rentalInsurancePage/RentalInsurancePageContainer";
import {
    ActivityParticipantsPageContainer
} from "../createSlice/features/pages/ActivityParticipants/ActivityParticipantsPageContainer";

const mapStateToProps = (state) => ({
    ...state.authentication,
    environment: state.environment,
    serviceModal: state.serviceModal,
    soldModal: state.soldModal,
    depotModal: state.depotModal,
    unlinkItemModal: state.unlinkItemModal,
    stolenModal: state.stolenModal
});

class SignedIn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            reset: 900,
            seconds: parseInt(props.startTimeInSeconds, 10) || 0,
            barcode: null
        };
    }

    componentDidMount() {
        this.props.dispatch(UserAction.list())
        this.props.dispatch(LocationAction.detail())
        this.props.dispatch(LocationAction.list())
    }

    render() {


        if (!this.props.environment.data.info.location_type_id) {

            return (
                <>

                    <FunctionKeys history={this.props.history}/>

                    <TimeTicker/>

                    <SearchBar/>

                    {this.props.depotModal.active && <DepotModalContainer/>}

                    <DashboardWrapper>

                        {this.props.serviceModal.active && <ServiceModalContainer/>}
                        {this.props.soldModal.active && <SoldModalContainer/>}
                        {this.props.unlinkItemModal.active && <UnlinkItemModalContainer/>}
                        {this.props.stolenModal.active && <StolenModalContainer/>}

                        <Switch>

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.Rental}
                                component={RentalOverviewPageContainer}
                                history={this.props.history}
                            />

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.ACTIVITY_PARTICIPANTS }
                                component={ActivityParticipantsPageContainer}
                                history={this.props.history}
                            />

                            {DisplayModuleByEnvironment('stock', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.ScanItems}
                                    component={ScanItems}
                                    history={this.props.history}
                                />
                            }

                            {DisplayModuleByEnvironment('availability', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.Availability}
                                    component={Availability}
                                    history={this.props.history}
                                />
                            }

                            {DisplayModuleByEnvironment('availability', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:shopSlug?' + RouteEnum.Settings}
                                    component={SettingsPageContainer}
                                    history={this.props.history}
                                />
                            }


                            {DisplayModuleByEnvironment('stock', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.Stock}
                                    component={Stock}
                                    history={this.props.history}
                                />
                            }

                            {DisplayModuleByEnvironment('stock', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.ItemsLate}
                                    component={ItemsLate}
                                    history={this.props.history}
                                />
                            }

                            {DisplayModuleByEnvironment('transactions', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.TransactionOverview}
                                    component={TransactionOverview}
                                    history={this.props.history}
                                />
                            }

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.Search + '/:searchString'}
                                component={SearchPageContainer}
                                history={this.props.history}
                            />

                            {/* Rental DETAIL */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.RentalDetail + '/:rentalUuid'}
                                component={RentalDetail}
                                history={this.props.history}
                            />

                            {/* Rental INSURANCE SETTINGS */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.RentalInsurance + '/:rentalUuid'}
                                component={RentalInsurancePageContainer}
                                history={this.props.history}
                            />

                            {/* Rental ISSUE ITEMS */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.RentalIssueItems + '/:rentalUuid'}
                                component={RentalItemsGivePageContainer}
                                history={this.props.history}
                            />

                            {/* Rental RETURN ITEMS */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.RentalReturnItems + '/:rentalUuid'}
                                component={RentalReturnItems}
                                history={this.props.history}
                            />

                            {/* Rental SWAP ITEMS */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.RentalSwapItems + '/:rentalUuid'}
                                component={RentalItemsExchangePageContainer}
                                history={this.props.history}
                            />

                            {/* Rental CREATE */}
                            {DisplayModuleByEnvironment('rentalCreate', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalCreate}
                                    component={RentalCreate}
                                    history={this.props.history}
                                />
                            }


                            {/* Rental CANCELLATION */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalCancellation + '/:rentalUuid'}
                                    component={RentalCancellation}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental ITEMS */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalItems + '/:rentalUuid'}
                                    component={RentalItems}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental CUSTOMER DATA CHANGE */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalCustomerData + '/:rentalUuid'}
                                    component={RentalAddress}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental DELIVERY CHANGE */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalDelivery + '/:rentalUuid'}
                                    component={RentalDeliveryMutation}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental PERIOD MUTATION */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug' + RouteEnum.RentalPeriod + '/:rentalUuid'}
                                    component={RentalPeriodMutation}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental TRANSACTION CHECK */}
                            {DisplayModuleByEnvironment('rentalMutations', this.props.environment, this.props.partnerUser) &&
                                <Route
                                    exact
                                    path={'/:partnerId/:partnerSlug/dashboard/rental/transaction-check/:rentalUuid'}
                                    component={TransactionCheck}
                                    history={this.props.history}
                                />
                            }

                            {/* Rental TRANSACTION STATUS */}

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug/dashboard/rental/:uuid/transaction/:transactionId/status'}
                                component={TransactionStatus}
                                history={this.props.history}
                            />

                            {/* Rental TRANSACTION */}
                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug/dashboard/rental/:uuid/transaction/:transactionId'}
                                component={Transaction}
                                history={this.props.history}
                            />

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.PRODUCT_INFO + '/:productId'}
                                component={ProductDetailPageContainer}
                                history={this.props.history}
                            />

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.PRODUCT_RENTALS + '/:productId'}
                                component={ProductRentalsPageContainer}
                                history={this.props.history}
                            />

                            <Route component={DashboardOverview}/>

                        </Switch>

                    </DashboardWrapper>

                </>
            )
        } else {
            return (
                <>
                    <TimeTicker/>
                    <DashboardWrapper>
                        <Switch>

                            <Route
                                exact
                                path={'/:partnerId/:partnerSlug' + RouteEnum.EVENT_DEMAND}
                                component={EventDemandPageContainer}
                                history={this.props.history}
                            />

                            <Route component={EventScanPageContainer}/>

                        </Switch>
                    </DashboardWrapper>
                </>
            )
        }
    }
}

export default connect(mapStateToProps)(SignedIn);
