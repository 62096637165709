import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {
    apiPartnerActivityParticipationList,
} from "../../../common/api/partner/item/activities/apiPartnerActivityParticipationList";
import {
    apiPartnerActivityParticipationUpdate
} from "../../../common/api/partner/item/activities/apiPartnerActivityParticipationUpdate";


export const loadActivityParticipantsList = createAsyncThunk(
    'activityParticipantsPage/load',
    async (data ) => {
        try {
            const response =
                await apiPartnerActivityParticipationList({id: data.categoryId, date: data.date})
            return {
                participantsList: response.data.items
            }
        } catch (e) {
            console.log(e)
        }
    }
)


export const updateActivityParticipantsUpdate = createAsyncThunk(
    'activityParticipantsPage/update',
    async (updatesParticipantsList, {getState}) => {
        const categoryId = getState().activityParticipantsPage.participantsList.categoryId
        const date = getState().activityParticipantsPage.participantsList.date
        try {
            const response =
                await apiPartnerActivityParticipationUpdate({
                    id: categoryId,
                    date: date
                }, {participants: updatesParticipantsList})
            return {
                participantsList: response.data.items
            }
        } catch (e) {
            console.log(e)
        }
    }
)

const initialState = {
    loading: false,
    participantsList: {}
}

export const activityParticipantsPageSlice = createSlice({
    name: 'activityParticipants',
    initialState,
    reducers: {
        resetParticipantsList: (state, action) => {
            state.participantsList = {}
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadActivityParticipantsList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(loadActivityParticipantsList.fulfilled, (state, action) => {
            state.loading = false
            const {participantsList} = action.payload

            if (participantsList.data) {
                state.participantsList = participantsList.data
            }
        })
        builder.addCase(updateActivityParticipantsUpdate.pending, () => {
        })
        builder.addCase(updateActivityParticipantsUpdate.fulfilled, () => {
        })
    },
})

export const {resetParticipantsList} = activityParticipantsPageSlice.actions

export const activityParticipantsPage = activityParticipantsPageSlice.reducer