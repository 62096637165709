import RentalListAction from "./RentalListAction";

export default class RentalListReducer {

    static initialState = {
        data: false,
        loading: false,
        sort: {},
        filters: {},
        activePage: 0,
    };

    static reducer(state = RentalListReducer.initialState, action) {


        switch (action.type) {

            case RentalListAction.REQUEST_LIST:
                return {
                    ...state,
                    loading: true
                };

            case RentalListAction.REQUEST_LIST + '_FINISHED':

                return {
                    ...state,
                    loading: false,
                    ...action.payload,
                }

            case RentalListAction.REQUEST_DETAIL:
                return {
                    ...state,
                    loading: true
                };

            case RentalListAction.SET_PAGE:

                return {
                    ...state,
                };

            case RentalListAction.SET_PAGE + '_FINISHED':

                return {
                    ...state,
                    activePage: action.payload

                }

            case RentalListAction.SET_LOADING + '_FINISHED':

                return {
                    ...state,
                    loading: action.payload
                }


            case RentalListAction.RESET + '_FINISHED':
                if (action.payload) {
                    return RentalListReducer.initialState
                } else {
                    return {...state}
                }

            case RentalListAction.SET_SORT + '_FINISHED':

                return {
                    ...state,
                    sort: action.payload
                };

            case RentalListAction.SET_FILTER + '_FINISHED':

                return {
                    ...state,
                    filters: action.payload
                };

            default:
                return state;
        }


    }
}