import ActionUtility from "../../common/helpers/ActionUtility";
import UserEffect from "./UserEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class UserAction {

    static REQUEST_LIST = 'UserAction.REQUEST_LIST';
    static REQUEST_DETAIL = 'UserAction.REQUEST_DETAIL';

    static list() {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/user/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }
            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_LIST, UserEffect.list, values);
        }

    }

    static detail(id) {


        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/user/detail?id=' + id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, UserAction.REQUEST_DETAIL, UserEffect.detail, values);
        }

    }

}
