import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import AuthenticationReducer from "./authentication/AuthenticationReducer";
import EnvironmentReducer from "./environment/EnvironmentReducer";
import UserReducer from "./user/UserReducer";
import CustomerReducer from "./customer/CustomerReducer";
import RentalCreateReducer from "./rental/create/RentalCreateReducer";
import CountryReducer from "./country/CountryReducer";
import LocationReducer from "./location/LocationReducer";
import CityReducer from "./city/CityReducer";
import RentalExtendReducer from "./rental/extend/RentalExtendReducer";
import PaymentReducer from "./payment/PaymentReducer";
import RentalCustomerDataReducer from "./rental/customerData/RentalCustomerDataReducer";
import RentalCancellationReducer from "./rental/cancellation/RentalCancellationReducer";
import RentalPeriodReducer from "./rental/period/RentalPeriodReducer";

import RentalDeliveryReducer from "./rental/delivery/RentalDeliveryReducer";
import TransactionReducer from "./transaction/TransactionReducer";
import RentalCategoryReducer from "./rental/category/RentalCategoryReducer";
import RentalTotalsReducer from "./rental/totals/RentalTotalsReducer";
import RentalIssueItemsReducer from "./rental/issueItems/RentalIssueItemsReducer";
import RentalReturnItemsReducer from "./rental/returnItems/RentalReturnItemsReducer";
import ItemReducer from "./item/ItemReducer";
import ErrorReducer from "./error/ErrorReducer";
import RentalDetailReducer from "./rental/detail/RentalDetailReducer";
import RentalListReducer from "../views/RentalOverview/store/RentalListReducer";
import DashboardReducer from "./dashboard/DashboardReducer";
import InsurancesReducer from "./insurance/list/InsurancesReducer";
import {productRentalsPageReducer} from "../views/productRentalsPage/productRentalsPageSlice";
import {productDetailReducer} from "../createSlice/features/pages/productDetailPage/productDetailPageSlice";
import {serviceModalReducer} from "../views/serviceModal/serviceModalSlice";
import {soldModalReducer} from "../views/soldModal/soldModalSlice";

import {depotModalReducer} from "../views/DepotModal/depotModalSlice";

import {
    rentalItemsExchangePageReducer
} from "../createSlice/features/pages/rentalItemsExchangePage/rentalItemsExchangePageSlice";
import {rentalItemsGivePageReducer} from "../createSlice/features/pages/rentalItemsGivePage/rentalItemsGivePageSlice";
import {unlinkItemModalReducerCs} from "../createSlice/features/modals/unlinkItemModal/unlinkItemModalSlice";
import {settingsReducer} from "../createSlice/features/pages/settingsPage/settingsPageSlice";
import {rentalOverviewPageReducer} from "../createSlice/features/pages/rentalOverviewPage/rentalOverviewPageSlice";
import {eventScanPageReducer} from "../createSlice/features/pages/EventScanPage/eventScanPageSlice";
import {eventDemandPageReducer} from "../createSlice/features/pages/EventDemandPage/eventDemandPageSlice";
import {rentalTotalsModuleReducer} from "../createSlice/features/modules/RentalTotalsModule/rentalTotalsModuleSlice";
import {searchPageReducer} from "../createSlice/features/pages/searchPage/searchPageSlice";
import {stolenModalReducer} from "../createSlice/features/modals/stolenModal/stolenModalSlice";
import {rentalInsurancePageReducer} from "../createSlice/features/pages/rentalInsurancePage/rentalInsurancePageSlice";
import {
    activityParticipantsPage
} from "../createSlice/features/pages/ActivityParticipants/ActivityParticipantsPageSlice";


const rootReducer = (history) => {

    const reducerMap = {
        router: connectRouter(history),
        authentication: AuthenticationReducer.reducer,
        environment: EnvironmentReducer.reducer,
        error: ErrorReducer.reducer,

        item: ItemReducer.reducer,

        dashboard: DashboardReducer.reducer,

        rentalList: RentalListReducer.reducer,
        rentalDetail: RentalDetailReducer.reducer,
        rentalCreate: RentalCreateReducer.reducer,
        rentalIssue: RentalIssueItemsReducer.reducer,
        rentalReturn: RentalReturnItemsReducer.reducer,

        rentalPeriod: RentalPeriodReducer.reducer,
        rentalItems: RentalCategoryReducer.reducer,
        rentalDelivery: RentalDeliveryReducer.reducer,
        rentalCustomerData: RentalCustomerDataReducer.reducer,
        rentalTotals: RentalTotalsReducer.reducer,

        rentalCancellation: RentalCancellationReducer.reducer,
        rentalExtend: RentalExtendReducer.reducer,

        insurances: InsurancesReducer.reducer,

        user: UserReducer.reducer,
        customer: CustomerReducer.reducer,
        partnerLocation: LocationReducer.reducer,
        country: CountryReducer.reducer,
        city: CityReducer.reducer,
        depotModal: depotModalReducer,
        searchPage: searchPageReducer,

        productRentalsPage: productRentalsPageReducer,
        productDetailPage: productDetailReducer,

        serviceModal: serviceModalReducer,
        soldModal: soldModalReducer,
        stolenModal: stolenModalReducer,

        eventScanPage: eventScanPageReducer,
        eventDemandPage: eventDemandPageReducer,

        payment: PaymentReducer.reducer,
        transaction: TransactionReducer.reducer,


        //createSlice...
        rentalItemsExchangePage: rentalItemsExchangePageReducer,
        rentalItemsGivePage: rentalItemsGivePageReducer,
        rentalInsurancePage: rentalInsurancePageReducer,
        unlinkItemModal: unlinkItemModalReducerCs,
        settingsPage: settingsReducer,
        rentalOverviewPage: rentalOverviewPageReducer,
        rentalTotalsModule: rentalTotalsModuleReducer,
        activityParticipantsPage: activityParticipantsPage
    };

    return combineReducers(reducerMap);
};

export default rootReducer
