import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import fetchHelper from "../../common/helpers/fetchHelper";
import ApiEnum from "../../common/constants/ApiEnum";

export const submitDepot = createAsyncThunk(
    'depotModal/submitDepot',
    async (_, {getState, dispatch}) => {

        const {depotModal} = getState()

        if (depotModal.item) {

            const updateStatusValues = {
                fetchUrl: ApiEnum.Api + '/item/return?id=' + depotModal.item.id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await fetchHelper(updateStatusValues, dispatch)

            if (depotModal.item.rental) {

                const unlinkRentalValues = {
                    fetchUrl: ApiEnum.Api + '/item/unlink-rental?id=' + depotModal.item.id + '&rental=' + depotModal.item.rental.uuid,
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    }
                }

                await fetchHelper(unlinkRentalValues, dispatch)

            }


            return true

        } else return false


    }
)

const initialState ={
    active: false,
    submitting: false,
    submitted: false,
}

export const depotModalSlice = createSlice({
    name: 'depotModal',
    initialState,
    reducers: {
        openDepotModal: (state, action) => {
            state.item = action.payload
            state.active = true
        },
        closeDepotModal: (state) => {
            state.active = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitDepot.pending, (state) => {
            state.submitting = true
            state.submitted = false
        })
        builder.addCase(submitDepot.fulfilled, (state) => {
            state.submitting = false
            state.submitted = true
            state.item = false
            state.active = false
        })
    },
})

export const {closeDepotModal, openDepotModal} = depotModalSlice.actions

export const depotModalReducer = depotModalSlice.reducer