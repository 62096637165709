import ErrorAction from "../error/ErrorAction";
import {globalConfig} from "../../configuration/config";

export default class CategoryEffect {

    static async countryList(values, dispatch) {

        return await fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status.code === 200) {

                dispatch(ErrorAction.unsetError())

                return {
                    response: response.items.countries,
                }

            } else {
                return {
                    error: true,
                    message: response.status.message
                }
            }

        }).catch(function (error) {

            dispatch(ErrorAction.catchError(error))

            return {
                error: true,
                message: error.message
            }
        })
    }

}


