export const apiEnum = {
    AUTH: '/auth',
    ENVIRONMENT: '/environment',
    PARTNER: '/partner',
    GENERAL: '/general',
    CLIENT: '/client',
    WEBSHOP: '/webshop',
    TASK_USER: '/task/user'
}

