import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalCreateEffect from "./RentalCreateEffect";
import moment from 'moment'
import FormatCartToRentalRequest from "../../../common/helpers/FormatCartToRentalRequest";
import ApiEnum from "../../../common/constants/ApiEnum";
import MapIssuesToCategories from "../../../common/helpers/MapIssuesToCategories";
import MapIssuesToRentalRequest from "../../../common/helpers/MapIssuesToRentalRequest";
import AuthenticationEffect from "../../authentication/AuthenticationEffect";

export default class RentalCreateAction {


    static SET_DELIVERY_LOCATION = 'RentalCreateAction.SET_DELIVERY_LOCATION'

    static SUBMIT = 'RentalCreateAction.SUBMIT';
    static RESET = 'RentalCreateAction.RESET';

    static submit(uuid = false, createTransaction = false) {
        return async (dispatch, getState) => {

            let fetchUrl = ApiEnum.Api + '/rental/create'

            if (uuid) {
                fetchUrl = ApiEnum.Api + '/rental/update?uuid=' + uuid
            }

            const data = {
                fetchUrl: fetchUrl,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    partner_id: getState().environment.data.info.partner_id,
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    pick_up: getState().rentalDelivery.delivery.enabled,
                    delivery: getState().rentalDelivery.delivery.enabled,
                    deliveryAddress: getState().rentalDelivery.delivery.deliveryPickupLocation.address,
                    pickupAddress: getState().rentalDelivery.delivery.deliveryPickupLocation.address,
                    date_rent_start: moment(getState().rentalPeriod.period.startDate).format('YYYY-MM-DD'),
                    date_rent_end: moment(getState().rentalPeriod.period.endDate).format('YYYY-MM-DD'),
                    time_unit_id: getState().rentalPeriod.period.chosenUnit,
                    time_rent_start: getState().rentalPeriod.period.startTime,
                    time_unit_amount: getState().rentalPeriod.period.amount,
                    insurance_id: getState().insurances.selected,
                    type: getState().rentalCustomerData.form.data.type,
                    customer: getState().rentalCustomerData.form.data.customer,
                    address: getState().rentalCustomerData.form.data.address,
                    remarks: getState().rentalCustomerData.form.data.remarks,
                    create_transaction: createTransaction
                }
            }

            if (getState().rentalTotals.discountType === 'fixed') {
                data.params.discount_price = getState().rentalTotals.discountPrice
            }

            if (getState().rentalCustomerData.form.data.document_number) {
                data.params.customer.document_number = getState().rentalCustomerData.form.data.document_number
            }

            if (getState().rentalCustomerData.form.data.document_type) {
                data.params.customer.document_type = getState().rentalCustomerData.form.data.document_type
            }

            if (getState().rentalTotals.discountType === 'percentage') {
                data.params.discount_percentage = getState().rentalTotals.discountPercentage
            }

            if (getState().rentalPeriod.period.startDate === moment().format('YYYY-MM-DD')) {
                data.params.categories = MapIssuesToRentalRequest(getState().rentalIssue.items)
            } else {
                data.params.categories = FormatCartToRentalRequest(getState().rentalItems.shoppingCart)
            }

            if (data.params.customer) {
                data.params.customer.id = getState().rentalCustomerData.form.data.id
            } else {
                data.params.customer = {
                    id: getState().rentalCustomerData.form.data.id
                }
            }

            if (data.params.type === 'private') {
                data.params.private = getState().rentalCustomerData.form.data.privatePerson
            } else {
                data.params.company = getState().rentalCustomerData.form.data.company
                data.params.contactPerson = getState().rentalCustomerData.form.data.contactPerson
            }

            const deliveryData = getState().rentalDelivery.delivery

            // Set delivery sub locations

            if (deliveryData.deliveryPickupLocation.value === 'custom') {

                data.params.deliveryAddress = {
                    street: deliveryData.custom.street,
                    number: deliveryData.custom.number,
                    zip_code: deliveryData.custom.zip_code,
                }

                if (deliveryData.custom.city['__isNew__']) {
                    data.params.deliveryAddress.city = deliveryData.custom.city.value
                } else {
                    data.params.deliveryAddress.city_id = deliveryData.custom.city.value
                }

            } else if (deliveryData.deliveryPickupSubLocation) {
                data.params.delivery_address_item_id = deliveryData.deliveryPickupSubLocation.id
                data.params.delivery_address_item_number = deliveryData.subLocationNumber.value
            }

            if (deliveryData.deliveryPickupLocation.value === 'custom') {

                data.params.pickupAddress = {
                    street: deliveryData.custom.street,
                    number: deliveryData.custom.number,
                    zip_code: deliveryData.custom.zip_code,
                }

                if (deliveryData.custom.city['__isNew__']) {
                    data.params.pickupAddress.city = deliveryData.custom.city.value
                } else {
                    data.params.pickupAddress.city_id = deliveryData.custom.city.value
                }

            } else if (deliveryData.deliveryPickupSubLocation) {
                data.params.pickup_address_item_id = deliveryData.deliveryPickupSubLocation.id
                data.params.pickup_address_item_number = deliveryData.subLocationNumber.value
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCreateAction.SUBMIT, RentalCreateEffect.submit, data);

        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCreateAction.RESET, RentalCreateEffect.reset);
        };
    }


}
