import ErrorAction from "../../stores/error/ErrorAction";
import rootStore from "../../stores/rootStore";
import rootReducer from "../../stores/rootReducer";
import {createMemoryHistory} from "history";
import {globalConfig} from "../../configuration/config";

let store
export const injectStoreFetch = _store => {
    store = _store
}

const fetchHelper = (values, dispatch) => {

    let language = 'nl'

    if (window.location.href.indexOf("/8/") > -1) {
        language = 'en'
    }

    if (values.fetchUrl.includes('?')) {

        values.fetchUrl += '&language=' + language

    } else {

        values.fetchUrl += '?language=' + language

    }


    if (values.method === 'post') {

        return fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: values.method,
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                ...values.params,
                master_partner_location_id: parseInt(localStorage.getItem(store.getState().environment.slug + '-partnerId'))
            })
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {

                dispatch(ErrorAction.unsetError())
                return response

            } else {
                dispatch(ErrorAction.setError(response))
            }

        }).catch(function (error) {
            dispatch(ErrorAction.catchError(error))
        })

    } else {

        return fetch(globalConfig.get().apiUrl + values.fetchUrl, {
            method: values.method,
            headers: {
                'Authorization': 'Bearer ' + values.accessToken,
                'Accept': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {

            if (response.status.code === 200) {

                dispatch(ErrorAction.unsetError())
                return response

            } else {
                dispatch(ErrorAction.setError(response))
            }

        }).catch(function (error) {
            dispatch(ErrorAction.catchError(error))
        })
    }

}

export default fetchHelper
