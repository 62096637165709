// Dependencies
import React from 'react'
import {connect} from "react-redux";
import {Formik, Field} from 'formik';

// Actions
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";
import EnvironmentAction from "../../stores/environment/EnvironmentAction";

// Components
import Notifications from "../../common/components/Notifications";
import translate from "../../createSlice/common/helpers/utils/translate";

const mapStateToProps = (state) => ({
    ...state.authentication,
    environment: state.environment
});

class Login extends React.Component {

    render() {

        const {dark} = this.props.environment.colors;
        const notifications = this.props.login.notifications;

        return (

            <Formik
                initialValues={
                    {
                        email: '',
                        password: ''
                    }
                }
                validate={values => {
                    this._validate(values)
                }}
                onSubmit={(values) => {
                    this._submitForm(values)
                }}
            >
                {({values, errors, touched, handleChange, handleBlur, handleSubmit}) => (

                    <form className="c-auth__form c-form" onSubmit={handleSubmit}>

                        <small>Inloggen</small>

                        <div className="c-form__row">
                            <div className="c-form__group">
                                <label htmlFor="email">{translate('emailAddress')}</label>
                                <Field data-hj-allow type="email" name={'email'} required id="email"/>
                            </div>
                        </div>

                        <div className="c-form__row">
                            <div className="c-form__group">
                                <label htmlFor="password">{translate('password')}</label>
                                <Field data-hj-allow type="password" name={'password'} id="password"/>
                            </div>
                        </div>

                        <div className="c-form__row">
                            <div className="c-form__group">
                                <button className="btn btn--primary" type="submit" style={{backgroundColor: dark}}>
                                    <span>{translate('login')}</span></button>
                            </div>
                        </div>

                        {!!notifications && <Notifications {...notifications}/>}

                    </form>
                )}
            </Formik>
        )

    }

    _validate = (values) => {
        const errors = {};
        if (!values.email) {
            errors.email = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
            errors.email = 'Invalid email address';
        }
        return errors;
    };

    _submitForm = (values) => {
        this.props.dispatch(EnvironmentAction.setLoader(true)).then(() => {
            this.props.dispatch(AuthenticationAction.login(values)).then(() => {
                this.props.dispatch(EnvironmentAction.setLoader(false))
            })
        })
    }

}

export default connect(mapStateToProps)(Login);

