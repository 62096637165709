// Dependencies
import React from 'react'
import {connect} from "react-redux";
import {withRouter, Link} from 'react-router-dom';
import {Helmet} from "react-helmet";

// Constants
import RouteEnum from "../../constants/RouteEnum";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";

// Helpers
import GetContrast from "../../helpers/GetContrast";
import CheckDisplayModule from "../../helpers/CheckDisplayModule";
import {MobileMenu} from "./MobileMenu";
import translate from "../../../createSlice/common/helpers/utils/translate";


const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    partnerUser: state.authentication.partnerUser,
    partnerLocation: state.partnerLocation.detail
});

class DashboardHeader extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            showMenu: false,
            loadChat: false,
            chatStatus: true
        }
    }


    toggleMobileMenu = () => {
        this.setState({
            showMenu: !this.state.showMenu
        })
    }


    render() {

        var element1 = document.getElementsByClassName("TrengoWidgetLauncher__iframe");
        var element2 = document.getElementsByClassName("TrengoOnlineStatus__iframe");
        var element3 = document.getElementsByClassName("TrengoWidgetGreeter__iframe");
        var element4 = document.getElementsByClassName("TrengoWidgetBadge__iframe");


        if (this.state.chatStatus) {
            if (element1[0]) {
                element1[0].classList.add("inActive");
            }
            if (element2[0]) {
                element2[0].classList.add("inActive");
            }
            if (element3[0]) {
                element3[0].classList.add("inActive");
            }
            if (element4[0]) {
                element4[0].classList.add("inActive");
            }
        } else {
            if (element1[0]) {
                element1[0].classList.remove("inActive");
            }
            if (element2[0]) {
                element2[0].classList.remove("inActive");
            }
            if (element3[0]) {
                element3[0].classList.remove("inActive");
            }
            if (element4[0]) {
                element4[0].classList.remove("inActive");
            }
        }


        const props = this.props;
        const {environment, partnerUser} = props;
        const {slug} = environment;
        const {dark, light} = environment.colors


        return (
            <>
                {!!this.state.loadChat &&
                <Helmet>

                    <script type="text/javascript">
                        {`  
                        window.Trengo = window.Trengo || {};
                        window.Trengo.key = 'Uy9MToROejT4zLy';
                        window.Trengo.extraOffsetY = '15rem';
                        (function (d, script, t) {
                        script = d.createElement('script');
                        script.type = 'text/javascript';
                        script.async = true;
                        script.src = 'https://static.widget.trengo.eu/embed.js';
                        d.getElementsByTagName('head')[0].appendChild(script);
                        }(document));
                        `}
                    </script>

                    <style>
                        {` 
                        .TrengoWidgetLauncher__iframe{
                        opacity: 1!important;
                        pointer-events: all!important;
                    }

                        .TrengoWidgetLauncher__iframe.inActive{
                        opacity: 0!important;
                        pointer-events: none!important;
                    }

                        .TrengoOnlineStatus__iframe{
                        opacity: 1!important;
                        pointer-events: all!important;
                    }

                        .TrengoOnlineStatus__iframe.inActive{
                        opacity: 0!important;
                        pointer-events: none!important;
                    }

                        .TrengoWidgetGreeter__iframe{
                        opacity: 1!important;
                        pointer-events: all!important;
                    }

                        .TrengoWidgetGreeter__iframe.inActive{
                        opacity: 0!important;
                        pointer-events: none!important;
                    }

                        .TrengoWidgetBadge__iframe{
                        opacity: 1!important;
                        pointer-events: all!important;
                    }

                        .TrengoWidgetBadge__iframe.inActive{
                        opacity: 0!important;
                        pointer-events: none!important;
                    }`}
                    </style>
                </Helmet>
                }

                <header className="c-header">
                    <div onClick={() => this._toHome()} className="c-header__logo">

                        <InnerStyle colors={environment.colors}/>

                        <img src={environment.data.logo} alt={'logo'}/>

                        {!!this.props.partnerLocation &&
                        <span className={'c-location-title'}>{this.props.partnerLocation.name}</span>}

                    </div>

                    <div className="c-header__misc">


                        <Link to={'/' + slug + RouteEnum.Dashboard} className="btn btn--small btn--outline"><span
                            style={{'color': dark}}>Dashboard</span></Link>

                        {CheckDisplayModule('rentalCreate', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              to={'/' + slug + RouteEnum.RentalCreate}
                              style={{'marginLeft': '.5rem', backgroundColor: dark}}>
                        <span
                            style={{color: GetContrast(dark)}}>{translate('newRental')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('availability', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: light}}
                              to={'/' + slug + RouteEnum.Availability}
                        >
                        <span
                            style={{color: GetContrast(light)}}>{translate('availability')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('stock', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: light}}
                              to={'/' + slug + RouteEnum.ScanItems}
                        >
                        <span
                            style={{color: GetContrast(light)}}>{translate('scanItems')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('stock', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: light}}
                              to={'/' + slug + RouteEnum.Stock}>
                            <span style={{color: GetContrast(light)}}>{translate('stock')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('stock', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: '#272727'}}
                              to={'/' + slug + RouteEnum.ItemsLate}>
                            <span style={{color: GetContrast(light)}}>{translate('itemsLate')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('openingTimes', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}
                              to={'/' + slug + RouteEnum.Settings}>
                            <span style={{color: '#000'}}>{translate('settings')}</span>
                        </Link>
                        }

                        {CheckDisplayModule('transactions', environment, partnerUser) &&
                        <Link className="btn btn--small"
                              style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}
                              to={'/' + slug + RouteEnum.TransactionOverview}
                        >
                            <span style={{color: '#000'}}>{translate('transactions')}</span>

                        </Link>
                        }

                        <a href="https://help.tilia.app/" target="_blank"
                           className="btn btn--small"
                           style={{'marginLeft': '.5rem', backgroundColor: '#eee'}}>
                            <span style={{color: '#000'}}>Helpdesk</span>
                        </a>

                        <button className="btn btn--small btn--outline" onClick={() => this._unsetUser()}>
                            <span>{translate('changeUser')} <small>(F12)</small></span>
                        </button>

                        <button className="btn btn--small btn--outline" onClick={() => this._openChatWidget()}>
                            <span>Chat</span>
                        </button>

                        <button className="btn btn--small btn--outline btn--square" onClick={() => this._logOut()}><i
                            style={{color: dark}}
                            className="icon icon-power"/>
                        </button>
                    </div>

                    <div className="c-header__menu-trigger" onClick={() => this.toggleMobileMenu()}>
                        <span/>
                        <span/>
                        <span/>
                    </div>

                </header>

                {!!this.state.showMenu &&
                <MobileMenu
                    toggleMenu={this.toggleMobileMenu}
                    dispatch={this.props.dispatch}
                    openChatWidget={this._openChatWidget}
                    environment={this.props.environment}
                    partnerUser={this.props.partnerUser}
                />
                }
            </>
        )


    }

    _toHome = () => {
        const {history} = this.props
        history.push('/' + this.props.environment.slug + RouteEnum.Dashboard)
    }


    _logOut = () => {
        localStorage.removeItem(this.props.environment.slug + '-partnerId')
        this.props.dispatch(AuthenticationAction.logout())
    }

    _unsetUser = () => {
        this.props.dispatch(AuthenticationAction.unsetUser())
    }

    _openChatWidget = () => {
        this.setState({
            chatStatus: !this.state.chatStatus,
            loadChat: true
        })
    }

}

const InnerStyle = (props) => {

    const {light, dark} = props.colors

    return (
        <style dangerouslySetInnerHTML={{
            __html: `
                  .pagination li.active a, .ReactVirtualized__Table__headerRow, .c-table-filter ul .is-active { background:` + light + `; color:  ` + GetContrast(light) + `;}
                  .ReactVirtualized__Table__headerRow span {color:` + GetContrast(light) + ` }

                  .c-icon-blocks__single.is-active{
                    background: ` + GetContrast(light) + ` !important;
                  }

                  .c-icon-blocks__single.is-active div{
                    background: ` + light + ` !important;
                  }

                  .c-icon-blocks__single.is-active div small{
                    color: ` + GetContrast(light) + ` !important;
                  }

                  .c-icon-blocks__single.is-active h2, .c-icon-blocks__single.is-active i{
                     color: ` + light + ` !important;
                  }

                  .ReactVirtualized__Table__row:hover, .c-table--custom thead tr { background:` + light + ` !important; color: ` + GetContrast(light) + ` !important; }
                    .c-select-customers__item.is-active, .c-order-handling__number, .c-order-handling-issue-numbers .c-order-handling__list__single__amount button {background:` + light + `; color: ` + GetContrast(dark) + ` !important }
                    .c-icon-blocks__single div small, .btn--outline i, .btn--outline span {color:` + dark + `; }
                 `
        }}/>
    )

}

export default connect(mapStateToProps)(withRouter(DashboardHeader));
