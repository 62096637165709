import translate from "../../createSlice/common/helpers/utils/translate";

const DashboardDisplayOptions = () => {
    return [
        {
            type: 'reservation',
            label: translate('reservations')
        },
        {
            type: 'return',
            label: translate('returns')
        },
        {
            type: 'registration',
            label:  translate('preRegisters')
        },
        {
            type: 'items',
            label:  translate('itemsGive')
        },
        {
            type: 'items-return',
            label: translate('itemsTake')
        },
        {
            type: 'late',
            label: translate('tooLate')
        }
    ]
}

export default DashboardDisplayOptions