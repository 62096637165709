import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerEventAvailability = async () => {

    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/event/availability', {}, {
    }).then(function (response) {

        return response

    }).catch(function (error) {

        return {
            data: {
                status: {
                    code: 500
                }
            }
        }

    })
}
