import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import ApiEnum from "../../../../common/constants/ApiEnum";
import fetchHelper from "../../../../common/helpers/fetchHelper";
import {camelizeKeys} from "humps";


export const loadProductDetail = createAsyncThunk(
    'productDetail/load',
    async (id, {getState, dispatch}) => {
        const itemValues = {
            fetchUrl: ApiEnum.Api + '/item/detail-by-id?id=' + id,
            method: 'post',
            accessToken: getState().authentication.accessToken,
            params: {
                auth_token: localStorage.getItem('authToken'),
                partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
            }
        }

        const rentalValues = {
            fetchUrl: ApiEnum.Api + '/item/history?id=' + id + '&limit=5',
            method: 'post',
            accessToken: getState().authentication.accessToken,
            params: {
                sort: "DESC",
                order_by: "id",
                auth_token: localStorage.getItem('authToken'),
                partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
            }
        }

        const itemResponse = await fetchHelper(itemValues, dispatch)
        const rentalResponse = await fetchHelper(rentalValues, dispatch)

        return {
            item:  itemResponse?.items?.item,
            rentals: camelizeKeys(rentalResponse?.items)    
        }
    }
)


const initialState = {
    loading: false,
}

export const productDetailPageSlice = createSlice({
    name: 'productDetail',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(loadProductDetail.fulfilled, (state, action) => {
            state.item = action.payload.item
            state.rentals = action.payload.rentals
        })
    },
})

export const {} = productDetailPageSlice.actions

export const productDetailReducer = productDetailPageSlice.reducer