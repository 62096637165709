const ApiEnum = {
    Api: '/partner',
    ApiGeneral:'/general',
    ApiClient: '/client',

    ApiWebshopAvailability: '/webshop/availability',
    ApiWebshopLocation: '/webshop/location',

    ApiAuthConnect: '/auth/connect',
    ApiAuthRefresh: '/auth/refresh'
}

export default ApiEnum
