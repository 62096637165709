import React from 'react'
import RentalIssueScannedItems from "../../../views/RentalIssueItems/components/RentalIssueScannedItems";
import RentalIssueNotifications from "../../../views/RentalIssueItems/components/RentalIssueNotifications";
import RentalIssueRemarks from "../../../views/RentalIssueItems/components/RentalIssueRemarks";
import RentalIssueItemsErrors from "../../../views/RentalIssueItems/components/RentalIssueItemsErrors";
import Scanner from "../General/Scanner";
import RentalCategoryAction from "../../../stores/rental/category/RentalCategoryAction";
import {connect} from "react-redux";
import RentalDispatcher from "../../dispatchers/RentalDispatcher";
import CustomBarcodeInput from "../General/CustomBarcodeInput";
import translate from "../../../createSlice/common/helpers/utils/translate";
import TinyLoader from "../General/TinyLoader";

const mapStateToProps = (state) => ({
        ...state.rentalIssue,
        rentalItems: state.rentalItems,
    }
);

class RentalCreateIssueItems extends React.Component {

    componentDidMount() {
        this.props.dispatch(RentalCategoryAction.getAvailableBicycles());
        this.props.dispatch(RentalCategoryAction.getAvailableAccessoires());
    }

    render() {
        const rentalItemsLoaded = this.props.rentalItems.bicycles ? Object.keys(this.props.rentalItems.bicycles).length > 0 : false;

        if (rentalItemsLoaded) {
            return (
                <div className="c-info-blocks__row issue-create">
                    <Scanner type={'issueCreate'}/>
                    <div className="c-info-blocks__single">
                        <header className="c-subject-header c-subject-header--closed">
                            <h2>{translate('scanItems')}</h2></header>
                        <div className="c-info-blocks__single__body">

                            <CustomBarcodeInput type={'issueCreate'}/>

                            <div className="c-form__row" style={{display: 'block'}}>
                                <RentalIssueScannedItems create={true}/>
                                <RentalIssueNotifications/>
                                <RentalIssueRemarks/>
                                <RentalIssueItemsErrors/>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else return <TinyLoader/>

    }

}

export default connect(mapStateToProps, RentalDispatcher)(RentalCreateIssueItems);
