import EnvironmentAction from "./EnvironmentAction";

export default class EnvironmentReducer {

    static initialState = {
        isLoading: false,
        rentalScannerEnabled: false,
        colors: {
            dark: null,
            light: null
        },
        error: false
    };

    static reducer(state = EnvironmentReducer.initialState, action) {


        switch (action.type) {

            case EnvironmentAction.DETAIL:
                return {
                    ...state,
                };

            case EnvironmentAction.DETAIL + '_FINISHED':

                if (action.payload.success) {
                    return {
                        ...state,
                        ...action.payload,
                        colors: {
                            dark: action.payload.data.info.primary_color_dark,
                            light: action.payload.data.info.primary_color_light
                        }
                    };
                } else return {
                    ...state,
                    error: true
                }


            case EnvironmentAction.SET_LOADER:
                return {
                    ...state,
                };

            case EnvironmentAction.SET_LOADER + '_FINISHED':

                return {
                    ...state,
                    isLoading: action.payload,
                };

            case EnvironmentAction.ENABLE_RENTAL_SCANNER:
                return {
                    ...state,
                };

            case EnvironmentAction.ENABLE_RENTAL_SCANNER + '_FINISHED':

                return {
                    ...state,
                    rentalScannerEnabled: action.payload,
                };

            default:
                return state;
        }
    }
}