import ActionUtility from "../../common/helpers/ActionUtility";
import ItemEffect from "./ItemEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class ItemAction {

    static SCAN_CODE = 'ItemAction.SCAN_CODE';

    static REQUEST_AVAILABLE_CATEGORY_LIST = 'ItemAction.REQUEST_AVAILABLE_CATEGORY_LIST';
    static REQUEST_AVAILABLE_ITEM_LIST = 'ItemAction.REQUEST_AVAILABLE_ITEM_LIST';
    static FORMAT_STOCK_ITEMS_BY_CATEGORY = 'ItemAction.FORMAT_STOCK_ITEMS_BY_CATEGORY';

    static EMPTY_SCANNED_ITEMS = 'ItemAction.EMPTY_SCANNED_ITEMS';

    static UPDATE_STATUS = 'ItemAction.UDPATE_STATUS';

    static REQUEST_LATE_ITEMS = 'ItemAction.REQUEST_LATE_ITEMS';
    static SET_LATE_ITEMS_PAGE = 'ItemAction.SET_LATE_ITEMS_PAGE';
    static REQUEST_LATE_PDF = 'ItemAction.REQUEST_LATE_PDF';

    static RESET = 'ItemAction.RESET';
    static SUBMIT = 'ItemAction.SUBMIT';

    static scanCode(code) {
        return async (dispatch, getState) => {

            let fetchUrl = ''

            if (code.includes("t29.nl/i/")) {
                code = code.replace('http://', '');
                code = code.replace('https://', '');
                code = code.replace('www.', '');
                code = code.replace('t29.nl/i/', '');
                fetchUrl = ApiEnum.Api + '/item/detail-by-id?id=' + code
            } else {
                code = code.replace('http://2cr.nl/?1ATX0', '');
                code = code.replace('http://2CR.NL/?1ATX0', '');
                code = code.replace('http://2cr.nl/?1ABB0', '');
                code = code.replace('http://2CR.NL/?1ABB0', '');
                code = code.split('!')[0]
                fetchUrl = ApiEnum.Api + '/item/detail-by-code?code=' + code
            }


            const values = {
                fetchUrl: fetchUrl,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.SCAN_CODE, ItemEffect.scanCode, values);
        };
    }

    static submit() {
        return async (dispatch, getState) => {
            const values = {

                unlinkValues: {
                    fetchUrl: ApiEnum.Api + '/item/unlink-items',
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                        items: getState().item.scannedItems.map((item) => {
                            return item.id
                        }),
                    },
                },

                linkValues: {
                    fetchUrl: ApiEnum.Api + '/item/link-location-all?location=' + localStorage.getItem(getState().environment.slug + '-partnerId'),
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                        items: getState().item.scannedItems.map((item) => {
                            return item.id
                        }),
                    },
                }

            }
            await ActionUtility.createThunkEffect(dispatch, ItemAction.SUBMIT, ItemEffect.submit, values);
        };
    }

    static requestAvailableCategorylist() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/item/category-list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    filter: {
                        partner_location: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    }
                },
            }


            await ActionUtility.createThunkEffect(dispatch, ItemAction.REQUEST_AVAILABLE_CATEGORY_LIST, ItemEffect.requestAvailableItemList, values);
        };
    }

    static updateStatus(itemId, statusCode) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/item/update-status?id=' + itemId + '=&code=' + statusCode,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                },
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.UPDATE_STATUS, ItemEffect.updateStatus, values);

        };

    }

    static requestAvailableItemList(filter = null) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/item/list?limit=99999&page=0&location_id=' + localStorage.getItem(getState().environment.slug + '-partnerId'),
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    filter: filter
                },
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.REQUEST_AVAILABLE_ITEM_LIST, ItemEffect.requestAvailableItemList, values);
        };
    }

    static requestLatePdf() {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/item/late-pdf',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                },
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.REQUEST_LATE_PDF, ItemEffect.requestLatePdf, values);
        };
    }

    static formatStockItemsByCategory() {
        return async (dispatch, getState) => {


            const values = {
                categories: getState().item.stockCategories,
                items: getState().item.stockItems
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.FORMAT_STOCK_ITEMS_BY_CATEGORY, ItemEffect.formatStockItemsByCategory, values);

        }
    }


    static emptyScannedItems() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ItemAction.EMPTY_SCANNED_ITEMS, ItemEffect.emptyScannedItems);
        };
    }

    static reset() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ItemAction.RESET, ItemEffect.reset);
        };
    }

    static requestLateItems() {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/item/late-items?page=' + getState().item.lateItems.activePage,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    filter: {
                        partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    }
                },
            }

            await ActionUtility.createThunkEffect(dispatch, ItemAction.REQUEST_LATE_ITEMS, ItemEffect.requestLateItems, values);
        };
    }

    static setLateItemsPage(pageNr) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ItemAction.SET_LATE_ITEMS_PAGE, ItemEffect.setLateItemsPage, pageNr);
        };
    }

}