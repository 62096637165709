import {apiEnum} from "../../../enums/apiEnum";
import {useAppAxios} from "../../../../app/hooks";
import {globalConfig} from "../../../../../configuration/config";

export const apiPartnerMutationCreateExchange = async (values) => {
    return await useAppAxios.post(globalConfig.get().apiUrl + apiEnum.PARTNER + '/mutation/create', {
        type: 'exchange',
        item_ids: values.takeItems.map((item) => item.id),
        exchanged_item_ids: values.giveItems.map((item) => item.id)
    }, {
        params: {
            rental: values.rentalUuid
        }
    }).then(function (response) {

        return response

    }).catch(function (error) {
        console.log(error)
        return {
            data: {
                status: {
                    code: 500
                }
            }
        }
    })
}
