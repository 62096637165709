import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import ApiEnum from "../../common/constants/ApiEnum";
import fetchHelper from "../../common/helpers/fetchHelper";


export const submitSold = createAsyncThunk(
    'soldModal/submitSold',
    async (_, {getState, dispatch}) => {

        const {soldModal} = getState();

        if (soldModal.item) {

            const updateStatusValues = {
                fetchUrl: ApiEnum.Api + '/item/update-status?id=' + soldModal.item.id + '&code=sold',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await fetchHelper(updateStatusValues, dispatch)

            if (soldModal.item.rental) {

                const unlinkRentalValues = {
                    fetchUrl: ApiEnum.Api + '/item/unlink-rental?id=' + soldModal.item.id + '&rental=' + soldModal.item.rental.uuid,
                    method: 'post',
                    accessToken: getState().authentication.accessToken,
                    params: {
                        auth_token: localStorage.getItem('authToken'),
                        partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    }
                }

                await fetchHelper(unlinkRentalValues, dispatch)

            }

            return true

        } else return false


    }
)

const initialState = {
    active: false,
    submitting: false,
    submitted: false,
}

export const soldModalSlice = createSlice({
    name: 'soldModal',
    initialState,
    reducers: {
        openSoldModal: (state, action) => {
            state.item = action.payload
            state.active = true
        },
        closeSoldModal: (state) => {
            state.active = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(submitSold.pending, (state) => {
            state.submitting = true
            state.submitted = false
        })
        builder.addCase(submitSold.fulfilled, (state) => {
            state.submitting = false
            state.submitted = true
            state.item = false
            state.active = false
        })
    },
})

export const {closeSoldModal, openSoldModal} = soldModalSlice.actions

export const soldModalReducer = soldModalSlice.reducer