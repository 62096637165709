import React from 'react'
import TinyLoader from "./TinyLoader";
import moment from "moment";

const AvailabilityTable = (props) => {

    if (props.weeklyAvailability) {

        const placeholder = Object.values(props.weeklyAvailability)[0];

        return (
            <div className={'c-info-blocks__single__body c-info-blocks__single__body--scroll'}>
                <div className="c-table c-table--dashboard c-Rental-overview">
                    <table className={'c-availability'}>
                        <thead>
                        <tr>
                            <th>Item</th>

                            {Object.keys(props.weeklyAvailability).map(dayKey => {

                                const date = moment(dayKey).format('DD-MM (dd)')

                                return (
                                    <th key={'availability-header-' + date}>{date}</th>
                                )
                            })}

                        </tr>
                        </thead>

                        <tbody>

                        {Object.values(placeholder).map((categoryItem) => {

                            const title = categoryItem.alternative_label ? categoryItem.alternative_label : categoryItem.name

                            return (
                                <tr>
                                    <td className={'c-bicycle-name'}>{title}</td>
                                    {Object.values(props.weeklyAvailability).map(day => {
                                        const item = Object.values(day).filter(item => item.name === categoryItem.name)[0]
                                        if (item) {
                                            return (<td>{item.available}</td>)
                                        }
                                    })}
                                </tr>
                            )

                        })}


                        </tbody>
                    </table>
                </div>
            </div>
        )
    } else {
        return <TinyLoader/>
    }

}

export default AvailabilityTable
