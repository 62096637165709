import ActionUtility from "../../common/helpers/ActionUtility";
import CustomerEffect from "./CustomerEffect";
import ApiEnum from "../../common/constants/ApiEnum";

export default class CustomerAction {

    static REQUEST_LIST = 'CustomerAction.REQUEST_LIST';
    static REQUEST_DETAIL = 'CustomerAction.REQUEST_DETAIL';

    static list() {

        return async (dispatch, getState) => {
            const values = {
                fetchUrl: ApiEnum.Api + '/customer/list',
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, CustomerAction.REQUEST_LIST, CustomerEffect.list, values);
        }

    }

    static detail(id) {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/customer/detail?id=' + id,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId'),
                }
            }

            await ActionUtility.createThunkEffect(dispatch, CustomerAction.REQUEST_DETAIL, CustomerEffect.detail, values);
        }

    }

}
