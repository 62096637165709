import React from 'react'
import {Route, BrowserRouter} from 'react-router-dom'
import '../App.css'
import ViewRouter from "./ViewRouter";
import AuthenticationAction from "../stores/authentication/AuthenticationAction";
import Loader from "./Loader";
import {connect} from "react-redux";
import ErrorDisplay from "../common/components/General/ErrorDisplay";


const mapStateToProps = (state) => ({
    environment: state.environment,
    authentication: state.authentication,
    rentalDetail: state.rentalDetail,
});

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authTokenGenerated: false
        }
    }

    componentDidMount() {
        this.props.dispatch(AuthenticationAction.requestAccessToken())
    }


    render() {

        const isLoading = (this.props.environment.isLoading || this.props.rentalDetail.loading)

        if (this.props.authentication.accessToken) {
            return (
                <BrowserRouter basename={'/'}>

                    <Loader loading={isLoading}/>

                    <ErrorDisplay/>

                    <Route
                        path={'/:partnerId/:partnerSlug?'}
                        history={this.props.history}
                        render={(props) => (
                            <ViewRouter {...props} />
                        )}
                    />

                </BrowserRouter>


            )
        } else {
            return <Loader/>
        }


    }

}

export default connect(mapStateToProps)(App);