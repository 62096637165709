import ActionUtility from "../../../common/helpers/ActionUtility";
import RentalCancellationEffect from "./RentalCancellationEffect";
import ApiEnum from "../../../common/constants/ApiEnum";

export default class RentalCancellationAction {

    static REQUEST_COSTS = 'RentalCancellationAction.REQUEST_COSTS';
    static CHANGE_REASON = 'RentalCancellationAction.CHANGE_REASON';
    static SUBMIT_MUTATION = 'RentalCancellationAction.SUBMIT_MUTATION';

    static requestCosts(uuid) {
        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/rental/cancellation-cost/?uuid=' + uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                rentalData: getState().rentalDetail.data.data,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    partner_location_id: localStorage.getItem(getState().environment.slug + '-partnerId')
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCancellationAction.REQUEST_COSTS, RentalCancellationEffect.requestCosts, values);
        }
    }

    static changeReason(reason) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, RentalCancellationAction.CHANGE_REASON, RentalCancellationEffect.changeReason, reason);
        }
    }


    static submitMutation() {

        return async (dispatch, getState) => {

            const values = {
                fetchUrl: ApiEnum.Api + '/mutation/create?rental=' + getState().rentalDetail.data.data.rental.uuid,
                method: 'post',
                accessToken: getState().authentication.accessToken,
                params: {
                    auth_token: localStorage.getItem('authToken'),
                    type: 'cancel',
                    remarks: getState().rentalCancellation.cancellationReason,
                    partnerLocationId: localStorage.getItem(getState().environment.slug + '-partnerId'),
                    discount_percentage: getState().rentalTotals.discountPercentage
                }
            }

            await ActionUtility.createThunkEffect(dispatch, RentalCancellationAction.SUBMIT_MUTATION, RentalCancellationEffect.submitMutation, values);
        }
    }
}
