export const defaultConfig = {
    apiUrl: "https://backoffice-vdl.idot.cloud/api",
    key: '3c3ab799-89c1-48a0-90cf-9fb17548db01',
    secret: '1442671c-1874-4dda-bf80-943e7be19888',
    environment: "PROD"
};

class GlobalConfig {
    config = defaultConfig;
    notDefinedYet = true;
    get(){
        if (this.notDefinedYet) {
            console.log('WARNING: No config is loaded, PRODUCTION IS USED!');
        }
        return this.config;
    }

    set(value) {
        if (this.notDefinedYet) {
            this.config = {
                ...this.config,
                ...value
            };
            this.notDefinedYet = false;
        } else {
            throw new Error("Global config has already been defined");
        }
    }}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = "/environment.json";